import React, { useCallback, useEffect, useRef, useState } from 'react';
import swal from "sweetalert";
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { CircularProgress } from 'react-md';
import { Wrapper, MandatoryLabel, SelectFieldSmall, Label, LeftDiv, Spacer, PopupButtons, TableContainer, overlayStyle, PopupContainer, CenterDiv, TextFieldLabel, MessageLabel, TextFieldSmall, Th, Td, TextField, TextArea, SelectField, Row, Column, RightDiv, SectionHeader, Button, SectionLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import styled from 'styled-components';
import SelectOptions from './SelectOptions';
import Popup from 'reactjs-popup';
import IPv6RoutingSettings from './IPv6RoutingSettings';
import IPv4RoutingSettings from './IPv4RoutingSettings';


const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 3px;
  height: auto;
  @media screen and (max-width: 500px) {
    width: 220px;
    justify-content: flex-end;
    margin-left:0px;
    font-size: 8px;
  }
`;

const ResponsiveFieldContainer = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
`;

const ResponsiveField = styled.div`
  display: flex;
  align-items: baseline;
  @media screen and (max-width: 750px) {
    width:100%; 
  }
`;
const ResponsiveFieldTimeout = styled.div`
  display: flex;
  align-items: baseline;
  @media screen and (max-width: 750px) {
    width:106%; 
  }
`;

const TextFieldLabelMS = styled(Label)`
  width: 190px;
  margin-top: 25px;
  font-size:12px;
   @media screen and (max-width: 400px) {
    font-size : 8px;
  }
`;

const MandatoryLabelDestination = styled(MandatoryLabel)`
    width : 100px;
    flex-shrink : 0;
    @media screen and (max-width:400px){
        width : 70px;
        margin-right : 0px;
    }
`;

const TextFieldLabelInterface = styled(TextFieldLabel)`
    width : 100px;
    flex-shrink : 0;
    @media screen and (max-width:400px){
        width : 70px;
        margin-right : 0px;
      }
`;

const SelectFieldSmallInterFace = styled(SelectFieldSmall)`
    width : 110px;
    margin-right : 20px;
    display: inline-block
    @media screen and (max-width:750px){
      width : 100%;
      margin-right : 0px;
    }
`;

const TextFieldLabelHostType = styled(TextFieldLabel)`
    width : 40px;
    flex-shrink : 0;
    @media screen and (max-width:750px){
        width : 100px;
        flex-shrink : 0;
        margin-right : 0px;
      }
      @media screen and (max-width:400px){
        width : 70px;
        flex-shrink : 0;
        margin-right : 0px;
      }
`;

const SelectFieldSmallHostType = styled(SelectFieldSmall)`
    width : 120px;
    margin-right : 20px;
    display: inline-block
    @media screen and (max-width:750px){
      width : 100%;
    margin-right : 0px;
    }
`;

const Div = styled.div`
  border-bottom: 1px solid lightgray;
  margin : -5px 0 15px 0;
`;


const ResponsiveInput = styled(TextFieldSmall)`
  width: 250px;
  @media screen and (max-width: 750px) {
    width: 160px;
  }
  @media screen and (max-width: 650px) {
    width: 100px;
  }
  @media screen and (max-width: 500px) {
    width: 120px;
  }
`;

const ResponsiveTable = styled(TableContainer)`
  height: 270px;
  @media screen and (max-width: 500px) {
    height: 230px;
  }
`;

const ResponsivePopup = styled.div`
  @media screen and (max-width: 750px) {
    width: 550px;
    height: 100%;
    overflow-y: auto;
  }
  @media screen and (max-width: 650px) {
    width: 450px;
  }
  @media screen and (max-width: 500px) {
    width: 350px;
  }
  @media screen and (max-width: 415px) {
    width: 300px;
  }
`;

const TextFieldSmallTime = styled(TextField)`
  width: 80px;
  @media screen and (max-width: 750px) {
    margin-left : 20px;
    width: 367px;
  }
  @media screen and (max-width: 400px) {
    margin-left : -10px;
    width: 175px;
  }
`;

const OutputDiv =styled.div`
  min-width : 504px;
  @media screen and (max-width: 1400px) {
    min-width : 504px;
  }
  @media screen and (max-width: 650px) {
    min-width : 100%;
  }
`;

const IPRoutingContainer = ({initialRouteData, isLoaded}) => {
    const [routeDataIpv4, setRouteDataIpv4] = useState();
    const [routeDataIpv6, setRouteDataIpv6] = useState();
    const [retryIpv4, setRetryIpv4]=useState(false);
    const [retryIpv6, setRetryIpv6]=useState(false);
    const [loadingIpv4, setIsLoadingIpv4]=useState(true);
    const [loadingIpv6, setIsLoadingIpv6]=useState(true);
    const [retry, setRetry] = useState(false)
    const [loading, setLoading] = useState(isLoaded);
    const [modalOpen, setModalOpen] = useState(false);
    const [popupType, setPopupType] = useState('');
    const [interfaceList, setInterfaceList] = useState();

    const contentStyle = {
        margin: 'auto',
        background: 'rgb(255, 255, 255)',
        width: 'fit-content',
        padding: '5px',
        border: '2px solid #d7d7d7'
    };

    const closePopup = (event) => {
        setModalOpen(false);
    };


    const getRoutesIpv4 = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client, {
            retries: 6,
            onRetry: (retryCount, error) => {
                if (retryCount < 5 && retryCount > 2) {
                    setRetryIpv4(true)
                } else if (retryCount === 6) {
                    swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
                }
            },
            retryDelay: () => { return 50; },
            retryCondition: (error) => {
                return true;
            },
        });
        client.get('/system/network/interfaces/show_ipv4_routes/status/all')
            .then(res => {
                let ifData = res.data.data;
                let sortedData = [];
                Object.keys(ifData).map((idx) => {
                    let data = {};
                    let pData = ifData[idx];
                    data.destination = pData[1].destination
                    data.gateway = pData[1].gateway
                    data.genmask = pData[1].genmask
                    data.metric = pData[1].metric
                    data.inf_name = pData[0]
                    sortedData.push(data);
                    return idx;
                });
                sortedData.sort(function (a, b) {
                    return a.metric - b.metric;
                });
                setRouteDataIpv4(sortedData);
                setIsLoadingIpv4(false)
            }).catch(err => {
                setIsLoadingIpv4(false)
                console.log(err);
            }).finally(() => {
                setIsLoadingIpv4(false);
            });
    }
  
    const getConfiguredInterfacelList = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client, {
            retries: 15,
            retryDelay: (retryCount, error) => {
                if (retryCount < 15 && retryCount > 5) {
                    setRetry(true)
                } else if (retryCount === 15) {
                    setRetry(false)
                    swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
                }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
        });
        client.get('/system/network/interfaces/all')
            .then(res => {
                const dataList = Object.keys(res.data.data.services)
                let sortedData = [];
                Object.keys(dataList).map((idx) => {
                    let data = {};
                    let pData = dataList[idx];
                    data.name = pData;
                    data.value = pData;
                    sortedData.push(data);
                    return idx;
                });
                setInterfaceList(sortedData);
            }).catch(err => {
                setLoading(true)
                console.log(err);
            });
    }

    const onClickPing = () => {
        setModalOpen(true);
        setPopupType('ping');
    };

    useEffect(() => {
        getConfiguredInterfacelList();
    }, []);

    const getRoutesIpv6 = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client, {
            retries: 6,
            onRetry: (retryCount, error) => {
                if (retryCount < 5 && retryCount > 2) {
                    setRetryIpv6(true)
                } else if (retryCount === 6) {
                    swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
                }
            },
            retryDelay: () => {
                return 500;
            },
            retryCondition: (error) => {
                return true;
            },
        });
        client.get('/system/network/interfaces/show_ipv6_routes/status/all')
            .then(res => {
                let ifData = res.data.data;
                let sortedData = [];
                Object.keys(ifData).map((idx) => {
                    let data = {};
                    let pData = ifData[idx];
                    data.destination = pData[1].destination
                    data.next_hop = pData[1].next_hop
                    data.metric = pData[1].metric
                    data.inf_name = pData[0]
                    sortedData.push(data);
                    return idx;
                });
                sortedData.sort(function (a, b) {
                    return a.metric - b.metric;
                });
                setRouteDataIpv6(sortedData);
                setIsLoadingIpv6(false)
            }).catch(err => {
                setIsLoadingIpv6(false)
                console.log(err);
            }).finally(() => {
                setIsLoadingIpv6(false)
            });
    }

    useEffect(() => {
        getRoutesIpv4();
        getRoutesIpv6();
    }, [])

    return (
    <>
        <Row>
            <ActionButtonContainer style={{ marginRight: '12px' }}>
                <Button primary id="ipv4_btnPing" name="btnPing" onClick={onClickPing}>Ping</Button>
                <Popup closeOnDocumentClick={false} modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                    <TestRoutePopup closePopup={closePopup} popupType={popupType} interfaceList={interfaceList} />
                </Popup>
            </ActionButtonContainer>
        </Row>
        <Spacer />
        {(loadingIpv4 || loadingIpv6) ?
            <div className="loading-msg">
                <label>{(retryIpv4 || retryIpv6) ? 'Retrying...' : 'Loading...'}</label>
                <CircularProgress />
            </div>
        :
        routeDataIpv4 === undefined && routeDataIpv6 ?
            <>
                <IPv6RoutingSettings initialRouteData={routeDataIpv6} isLoading={loadingIpv6} />
                <Spacer />
                <IPv4RoutingSettings initialRouteData={routeDataIpv4} isLoading={loadingIpv4} />
                <Spacer />
            </>
        :
            <>
                <IPv4RoutingSettings initialRouteData={routeDataIpv4} isLoading={loadingIpv4} />
                <Spacer />
                <IPv6RoutingSettings initialRouteData={routeDataIpv6} isLoading={loadingIpv6} />
                <Spacer />
            </>
        }
    </>);
}

const DEFAULT_PING_VALUE = {
    'hosts': '',
    'interface': 'default',
    'timeout': '100',
    'ip_version': 'auto',
}

const TestRoutePopup = (props) => {
    const { closePopup, popupType, interfaceList } = props;
    const [destination, setDestination] = useState('8.8.8.8');
    const [routesData, setRoutesData] = useState([]);
    const [loading, setLoading] = useState();
    const [pingLoading, setPingLoading] = useState();
    const [pingData, setPingData] = useState(DEFAULT_PING_VALUE);
    const [output, setOutput] = useState();
    const [disableSubmitPingButton, setSubmitPingDisableButton] = useState(false);
    const [disableResetPingButton, setDisableRestPingButton] = useState(false);
    const [disableSubmitTestButton, setDisableSubmitTestButton] = useState(false);
    const [disableResetTestButton, setDisableRestTestButton] = useState(false);

    const onSubmit = (destination) => {
        const value = validateIPaddress(destination);
        if (value) {
            getDestination();
        }
    }

    const getDestination = () => {
        setLoading(true);
        setDisableSubmitTestButton(true);
        axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/get_routes/' + destination + '/status/all')
            .then(res => {
                let ifData = res.data.data[0];
                let data = {};
                let sortedData = [];
                let routesDataArray = [...routesData];
                Object.keys(ifData).map((idx) => {
                    data.destination = destination;
                    data.ifName = ifData[idx].device;
                    data.gateway = ifData[idx].gateway;
                    data.source = ifData[idx].source
                    sortedData.push(data);
                    return idx;
                })
                if (Object.keys(routesDataArray).length > 9) {
                    routesDataArray.splice(0, 0, data);
                    routesDataArray.splice(-1);
                } else {
                    routesDataArray.splice(0, 0, data);
                }
                setRoutesData(routesDataArray);
                setLoading(false);
                setDisableSubmitTestButton(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
                setDisableSubmitTestButton(false);
            });
    }

    function validateIPaddress(ipaddress) {

        if (ipaddress === undefined || ipaddress === '') {
            swal('Please enter IPv4 destination address.', { icon: 'error' });
            return;
        } else {
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
                return true
            }
            swal('Please enter valid IPv4 destination address.', { icon: 'error' });
            return false
        }
    }

    const handleOnChange = (event) => {
        setDisableSubmitTestButton(false);
        if (event.target.name === 'destination' && event.target.value.includes(' ')) {
            event.target.value = event.target.value.replace(/\s/g, '');
        } else {
            if (event.target.name === 'destination') {
                event.target.value = event.target.value.replace(/[^.:\w]/g, '');
            }
            setDestination(event.target.value);
        }
    }

    const pingHandleOnChange = (event) => {
        if (event.target.name === 'hosts') {
            event.target.value = event.target.value.replace(/,\s+/g, ', ').replace(/ \s+/g, ' ').replace(/^ /, "");
        }
        if ((event.target.name === 'timeout') && event.target.value.includes(' ')) {
            event.target.value = event.target.value.replace(/\s/g, '');
        } else if (event.target.name === 'hosts' && event.target.value.includes('..')) {
            event.target.value = event.target.value.replace('.');
        } else {
            if (event.target.name === 'timeout') {
                event.target.value = event.target.value.replace(/^0/, "").replace(/[^\d]/g, '');
            }
            setPingData({ ...pingData, [event.target.name]: event.target.value });
        }
    }

    const onReset = () => {
        setDisableSubmitTestButton(false);
        setDestination('');
    }

    const onPingReset = () => {
        setPingData(DEFAULT_PING_VALUE);
        setOutput('')
    }

    function validIPv4HostAddress(ipaddress) {
        var res = ipaddress.match(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
        if (ipaddress === '') {
            return;
        } else {
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
                return true;
            } else if (res) {
                if (!ipaddress.includes('.')) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    };

    function validIPv6HostAddress(ipaddress) {
        var hostName = ipaddress.match(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
        if (ipaddress === '') {
            return;
        } else {
            if (/^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm.test(ipaddress)) {
                return true;
            } else if (hostName) {
                if (!ipaddress.includes('.')) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    };

    function validIPv4IPv6HostAddress(ipaddress) {
        var hostName = ipaddress.match(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
        if (ipaddress === '') {
            return;
        } else {
            if (/^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm.test(ipaddress)) {
                return true;
            } else if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
                return true;
            } else if (hostName) {
                if (!ipaddress.includes('.')) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    };


    const onPingSubmit = () => {
        setSubmitPingDisableButton(true);
        setOutput()
        setPingLoading(true);
        let jsonData = Object.assign({}, pingData);
        let hosts = jsonData.hosts;
        let timeout = jsonData.timeout;
        let ip_version = jsonData.ip_version;
        let arrHost = [];

        if (hosts === undefined || hosts === '') {
            swal({ text: 'Please enter destination', icon: 'error' });
            setPingLoading(false);
            setSubmitPingDisableButton(false);
            return;
        }

        if (timeout === undefined || timeout === '') {
            swal({ text: 'Please enter timeout', icon: 'error' });
            setPingLoading(false);
            setSubmitPingDisableButton(false);
            return;
        }

        let destination = [hosts];
        let removeSpace = destination.toString().replace(/\s/g, "");
        let splitDestination = removeSpace.toString().split(',');

        let duplicateDestination = false;

        duplicateDestination = splitDestination.some((element, index) => {
            return splitDestination.indexOf(element) !== index;
        });
        if(duplicateDestination){
            swal(`Duplicate destination not allowed.` , { icon: 'error'});
            setPingLoading(false);
            setSubmitPingDisableButton(false);
            return;
        }

        var res = hosts.toString().charAt(hosts.toString().trim().length-1);
        let hostIndex = [];

        if(res === ',') {
            swal('Destination should be separated by comma, comma at the end is not allowed.', { icon: 'error'});
            setSubmitPingDisableButton(false);
            setPingLoading(false);
            return;
        } else {
            let splitHost = hosts.toString().split(',');
            if(splitHost.length > 10){
            swal('Maximum 10 numbers of destination can be added', { icon: 'error' });
            setPingLoading(false);
            setSubmitPingDisableButton(false);
            return;
            }
            for (let i = 0; i < splitHost.length; i++) {
                if (splitHost[i].trim().includes(' ')) {
                    hostIndex.push(splitHost[i].trim());
                    swal(`Destination "${hostIndex}" should be separated by comma.` , { icon: 'error'});
                    setPingLoading(false);
                    setSubmitPingDisableButton(false);
                    return;
                }
            }
        };

        if (hosts !== undefined) {
            let invalidhosts = [];
            let host = hosts.split(',');
            arrHost.push(host);
            for (let i = 0; i < host.length; i++) {
                var regExp = /[a-zA-Z]/g;
                if (ip_version === "ipv4") {
                    for (let i = 0; i < host.length; i++) {
                        const value = validIPv4HostAddress(host[i].trim());
                        if (!value) {
                            invalidhosts.push(host[i].trim());
                        }
                    }
                    if (invalidhosts.length > 0) {
                        const invalidAddresses = invalidhosts.join(', ');
                        swal({ text: `Please enter valid destination '${invalidAddresses}'`, icon: 'error' });
                        setSubmitPingDisableButton(false);
                        setPingLoading(false);
                        return;
                    }
                }
                if (ip_version === "ipv6") {
                        for (let i = 0; i < host.length; i++) {
                            const value = validIPv6HostAddress(host[i].trim());
                            if (!value) {
                                invalidhosts.push(host[i].trim());
                            }
                        }
                        if (invalidhosts.length > 0) {
                            const invalidAddresses = invalidhosts.join(', ');
                            swal({ text: `Please enter valid destination '${invalidAddresses}'`, icon: 'error' });
                            setSubmitPingDisableButton(false);
                            setPingLoading(false);
                            return;
                        }
                }
                if (ip_version === "auto") {
                    for (let i = 0; i < host.length; i++) {
                        const value = validIPv4IPv6HostAddress(host[i].trim());
                        if (!value) {
                            invalidhosts.push(host[i].trim());
                        }
                    }
                    if (invalidhosts.length > 0) {
                        const invalidAddresses = invalidhosts.join(', ');
                        swal({ text: `Please enter valid destination '${invalidAddresses}'`, icon: 'error' });
                        setSubmitPingDisableButton(false);
                        setPingLoading(false);
                        return;
                    }
                }
            }
        }

        if (timeout > 5000) {
            swal('Please enter timeout value <= 5000ms', { icon: 'error' });
            setPingLoading(false);
            setSubmitPingDisableButton(false);
            return;
        }

        let finalJson = {};
        finalJson.action = 'ping'
        finalJson.source = 'edge_ui'
        finalJson.data = {};
        finalJson.data.interface = jsonData.interface;
        finalJson.data.hosts = arrHost[0]
        finalJson.data.timeout = jsonData.timeout;
        finalJson.data.ip_version = jsonData.ip_version;

        axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/all', finalJson)
            .then(res => {
                setOutput(res.data.data)
                setPingLoading(false)
                setSubmitPingDisableButton(false);
            }).catch(error => {
                setPingLoading(false);
                if (error.response) {
                    var errorObj = error.response.data;
                    setSubmitPingDisableButton(false);
                    swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
                } else {
                    setSubmitPingDisableButton(false);
                    swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
                }
            })
    }

    useEffect(() => {
        if (output && pingData.hosts && pingData.timeout && pingData.interface) {
            setDisableRestPingButton(false);
        } else {
            setDisableRestPingButton(true);
        }
    }, [output, pingData]);

    useEffect(() => {
        if (destination) {
            setDisableRestTestButton(false);
        } else {
            setDisableRestTestButton(true);
        }
    }, [destination]);

    const OnhandleKeyPress = (event, destination) => {
        if (event.key === "Enter") {
            onSubmit(destination);
        }
    }
    return (
        <ResponsivePopup>
            <PopupContainer>
                {popupType === 'ping' &&
                    <>
                        <SectionHeader style={{ 'display': 'flex' }}>
                            <SectionLabel>Ping</SectionLabel>
                        </SectionHeader>
                        <Container>
                            <Row style={{ 'display': 'flex', 'width': '100%', 'flexDirection': 'column' }}>
                                <ResponsiveField>
                                    <MandatoryLabelDestination>Destination</MandatoryLabelDestination>
                                    <TextFieldSmall style={{ 'width': '100%', 'textTransform': 'lowercase' }} id="destination_address" autoComplete="off" placeholder={pingData.ip_version === 'ipv4' && '8.8.8.8, google.com' || pingData.ip_version === 'ipv6' && '2001:4860:4860::8888, ipv6.google.com' || pingData.ip_version === 'auto' && '8.8.8.8, 2001:4860:4860::8888, google.com'} value={pingData.hosts} onChange={pingHandleOnChange} name="hosts" />
                                </ResponsiveField>
                                <ResponsiveFieldContainer>
                                    <ResponsiveField>
                                        <TextFieldLabelInterface>Interface</TextFieldLabelInterface>
                                        <SelectFieldSmallInterFace id='interface' name='interface' value={pingData.interface} onChange={pingHandleOnChange}>
                                            <option value='default'>auto</option>
                                            <SelectOptions items={interfaceList} />
                                        </SelectFieldSmallInterFace>
                                    </ResponsiveField>
                                    <ResponsiveField>
                                        <TextFieldLabelHostType>Use</TextFieldLabelHostType>
                                        <SelectFieldSmallHostType id='ip_version' name='ip_version' value={pingData.ip_version} onChange={pingHandleOnChange}>
                                            <option value='auto'>IPv4/IPv6</option>
                                            <option value='ipv4'>IPv4</option>
                                            <option value='ipv6'>IPv6</option>
                                        </SelectFieldSmallHostType>
                                    </ResponsiveField>
                                    <ResponsiveFieldTimeout>
                                        <MandatoryLabel style={{ 'width': '80px', 'flex-shrink': '0' }}>Timeout</MandatoryLabel>
                                        <TextFieldSmallTime id="timeout" autoComplete="off" name="timeout" maxLength='4' value={pingData.timeout} onChange={pingHandleOnChange} />
                                        <TextFieldLabelMS style={{ 'width': '30px', 'marginLeft': '8px' }}>ms</TextFieldLabelMS>
                                    </ResponsiveFieldTimeout>
                                </ResponsiveFieldContainer>
                                <PopupButtons style={{ 'marginTop': '20px' }}>
                                    <Button primary id='btndisabled' style={{ 'margin': '0' }} disabled={disableSubmitPingButton} onClick={onPingSubmit}>Submit</Button>
                                </PopupButtons>
                                <div>
                                    <TextFieldLabel style={{ 'width': '70px', 'marginBottom': '10px', 'marginTop': '0' }}>Output</TextFieldLabel>
                                    <OutputDiv style={{ 'height': '150px', 'border': '1px solid #ddd', 'padding': '15px', 'overflow': 'auto' }}>
                                        {pingLoading &&
                                            <div style={{ 'textAlign': 'center', 'paddingTop': '10px' }}>
                                                <label>Pinging...</label>
                                                <CircularProgress />
                                            </div>}
                                        {(output) && output.map((idx) => (
                                            <p>{idx}</p>
                                        ))}
                                    </OutputDiv>
                                </div>
                            </Row>
                            <Row>
                                <PopupButtons>
                                    <Button primary id="btndisabled" disabled={disableResetPingButton} onClick={onPingReset}>Reset</Button>
                                    <Button id="btnCancelIface" danger onClick={() => { closePopup(); }}>Cancel</Button>
                                </PopupButtons>
                            </Row>
                        </Container>
                    </>}
                {popupType === 'routeTest' &&
                    <>
                        <SectionHeader style={{ 'display': 'flex' }}>
                            <SectionLabel>Test Route (IPv4)</SectionLabel>
                        </SectionHeader>
                        <Container>
                            <Row style={{ 'display': 'flex', 'width': '100%', 'align-items': 'baseline', 'flex-wrap': 'wrap' }}>
                                <div>
                                    <MandatoryLabel style={{ 'width': '100px' }}>Destination</MandatoryLabel>
                                    <ResponsiveInput style={{ 'textTransform': 'lowercase' }} id="ipv4_destination" autoComplete="off" placeholder='8.8.8.8' value={destination} onKeyPress={(e) => OnhandleKeyPress(e, destination)} name="destination" onChange={handleOnChange} />
                                </div>
                                <ButtonContainer>
                                    <Button primary autoFocus disabled={loading || disableSubmitTestButton} id='ipv4_btndisabled' onClick={() => onSubmit(destination)}>
                                        <div style={{ 'display': 'flex' }}>
                                            {loading && <span>Submit</span>}
                                            {!loading && <span style={{ 'margin': '0 12.5px' }}>Submit</span>}
                                            {loading && (
                                                <div class="loader" style={{ 'marginLeft': '10px', 'marginTop': '2px' }}></div>
                                            )}
                                        </div>
                                    </Button>
                                    <Button primary id="btndisabled" style={{ 'marginLeft': '10px' }} name="btndisabled" disabled={disableResetTestButton} onClick={onReset}>Clear</Button>
                                </ButtonContainer>
                            </Row>
                            <ResponsiveTable className='tableFixHead' style={{ 'marginTop': '10px' }}>
                                <table style={{ 'min-width': '100%', 'borderSpacing': '0', 'tableLayout': 'fixed', 'borderCollapse': 'separate' }}>
                                    <tr style={{ 'background-color': '#1f303a', 'borderSpacing': '0', 'color': 'white', 'position': 'sticky', 'top': '0' }}>
                                        <Th>Destination</Th>
                                        <Th>Gateway</Th>
                                        <Th>Interface</Th>
                                        <Th>Source</Th>
                                    </tr>
                                    {Object.keys(routesData).map((index) => {
                                        return (
                                            <tr>
                                                <Td style={{ 'padding': '9px', 'textTransform': 'lowercase' }}>{(routesData[index].destination === null || routesData[index].destination === '') ? '-' : routesData[index].destination}</Td>
                                                <Td>{(routesData[index].gateway === null || routesData[index].gateway === '') ? '-' : routesData[index].gateway}</Td>
                                                <Td>{(routesData[index].ifName === null || routesData[index].ifName === '') ? '-' : routesData[index].ifName}</Td>
                                                <Td>{(routesData[index].source === null || routesData[index].source === '') ? '-' : routesData[index].source}</Td>
                                            </tr>
                                        );
                                    }
                                    )}
                                </table>
                            </ResponsiveTable>
                            <Row>
                                <PopupButtons>
                                    <Button id="btnCancel" name="btnCancel" onClick={closePopup}>Cancel</Button>
                                </PopupButtons>
                            </Row>
                        </Container>
                    </>}
            </PopupContainer>
        </ResponsivePopup>
    );
}

export default IPRoutingContainer;