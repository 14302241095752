import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { SelectionControl, CircularProgress } from 'react-md';
import { Row, Button, SectionHeader, SelectField, SectionLabel, CenterDiv, Label, Td, Th, overlayStyle, TableContainer, PopupButtons, PopupContainer, TextFieldLabel, TextFieldSmall, MandatoryLabel, Link1, LinkIcon } from '../utils/Styles';
import MetabaseLink from './MetaBaseLink'
import * as Constants from '../utils/Constants';
import { isEmpty } from '../utils/Utils';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import axiosRetry from 'axios-retry';
import { convertToTimeZone } from 'date-fns-timezone';
import { format, toDate } from 'date-fns';


const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const ResponsiveTextFieldLabel = styled(TextFieldLabel)`
  @media screen and (max-width: 575px) {
    width: 140px;
  }
  @media screen and (max-width: 450px) {
    width: 120px;
  }
`;

const ResponsiveTextFieldSmall = styled(TextFieldSmall)`
  @media screen and (max-width: 575px) {
    width: 180px;
  }
  @media screen and (max-width: 450px) {
    width: 140px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
  @media screen and (max-width: 750px) {
    font-size: 0.95rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

const ContentDiv = styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ResponsiveTabs = styled(Tabs)`
  @media screen and (max-width: 500px) {
    width: 320px;
  }
`;

const ResponsiveTabContent = styled.div`
  padding: 5px 16px 10px;
  overflow: hidden auto;
  scrollbar-width: thin;
  scrollbar-color: #A8B5BD7D transparent;
  ::-webkit-scrollbar {
    height: 4px;
    width: 6px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #6262627d;
    border-radius: 10px;
  }
`;

const ResponsiveRow = styled(Row)`
  display: flex;
  align-items: baseline;
  & > ${TextFieldSmall} {
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${TextFieldSmall}{
    width: 100%;
  }
  & > div > ${SelectField} {
    width: 217px;
  }
  & > ${MandatoryLabel} {
    width: 253px;
    flex-shrink: 0;
    @media screen and (max-width: 680px) {
      width: 175px;
    }
  }
`;

const ResponsiveRow2 = styled(Row)`
  display: flex;
  align-items: baseline;
  & > ${TextFieldSmall} {
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${TextFieldSmall}{
    width: 100%;
  }
  & > div > ${SelectField} {
    width: 217px;
  }
  & > ${MandatoryLabel} {
    width: 253px;
    padding: 0px 0px 0px 23px;
    flex-shrink: 0;
    @media screen and (max-width: 680px) {
      width: 175px;
      padding: 0px 0px 0px 17px;
    }
  }
`;

const ResponsiveRow1 = styled(Row)`
  display: flex;
  align-items: baseline;
  & > ${TextFieldSmall} {
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${TextFieldSmall}{
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${SelectField} {
    width: 240px;
    @media screen and (max-width: 400px) {
      width: 101px;
    }
  }
  & > ${MandatoryLabel} {
    width: 253px;
    flex-shrink: 0;
    @media screen and (max-width: 680px) {
      width: 175px;
    }
  }
`;

const ResponsivePopupContainer = styled.div`
  width:  100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  z-index: 5;
  & > div {
    @media screen and (max-width: 750px) {
      transform: scale(0.9);
      background: transparent !important;
    }
    @media screen and (max-width: 480px) {
      transform: scale(0.8);
    }
    @media screen and (max-width: 450px) {
      height: 600px !important;
    }
    @media screen and (max-width: 400px) {
      transform: scale(0.75);
    }
  }
`;

const ResponsiveMandatoryLabelGeneral = styled(MandatoryLabel)`
  @media screen and (max-width: 575px) {
    width: 140px;
  }
  @media screen and (max-width: 450px) {
    width: 120px;
  }
`;

const SectionHeaderMetabase=styled.div`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;
`;

export const OnRefreshContext = React.createContext({
  onRefresh: false
});

function between(x, min, max) {
  return x >= min && x <= max;
}

const generateDeviceRam = () => {
  axios.get(Constants.EDGE_API_ENDPOINT + '/devices/deviceinfo')
  .then(res => {
    let systemInfo = res.data.data;
    let value = systemInfo.system.ram.indexOf(',') !== -1 ? systemInfo.system.ram.substr(7, systemInfo.system.ram.indexOf(',')-7) : systemInfo.system.ram;
    let formattedData = Number(parseFloat(value.replace('MB','')).toFixed(0)).toLocaleString('en', {minimumFractionDigits: 0});
    window.localStorage.setItem('device_ram', formattedData.replace(',',''));
  })
  let ram = window.localStorage.getItem('device_ram');
  let GBram = parseInt(ram) / 1024;
  let result = '';
  if(Math.floor(GBram) <= 4) {
    result =parseInt(8, 10);
  } else if(between(GBram, 4, 8)) {
    result = parseInt(16, 10);
  } else if(between(GBram, 8, 16)) {
    result = parseInt(32, 10);
  } else if(between(GBram, 16, 32)) {
    result = parseInt(64, 10);
  }else if(between(GBram, 32, 64)) {
    result = parseInt(128, 10);
  } else if(between(GBram, 64, 128)) {
    result = parseInt(256, 10);
  } else if(Math.floor(GBram) >= 128) {
    result = parseInt(512, 10);
  }
  return result.toString();
};

const Metabase = (props) => {
  const [isEditPopup, setEditPopup] = useState(true);
  const [metabaseData, setMetabaseData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState({});
  const [loading, setLoading] = useState(false);
  const [onRefresh, setOnRefresh] = useState(false);
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const [CurTimezone, setCurTimeZone]=useState();
  const [standaredTZ, setStandardTZ]=useState();
  const [retry,setRetry]=useState(false)
  let pathArray = window.location.pathname.split('/');
  const basename = pathArray[2];

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '25%',
    border: '2px solid #d7d7d7'
  };

  const instNameStyle = {
    textAlign: 'left',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: window.innerWidth >= 1920 ? '300px' : '200px'
  };

  const nickNameStyle = {
    textAlign: 'left',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: window.innerWidth >= 1920 ? '300px' : '200px'
  };

  useEffect(()=>{
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  },[]);


  const editInstance = () => {
    setEditPopup(true);
    setModalOpen(true);
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const addNewInstance = (event) => {
    setEditPopup(false);
    // setSelectedInstance(undefined);
    setModalOpen(true);
  };

  const radioButtonOnChange = (name) => {
    let instData = Object.assign({}, metabaseData.stacks[name].services.metabase1);
    instData.instance_id = name;
    instData.nickname = metabaseData.stacks[name].nickname;
    setSelectedInstance(instData);
    setEditPopup(true);
  };

  const enableToggle = (value, event) => {
    let metabase_name = event.target.name;
    var actionLabel = value ? "enable" : "disable";

    var jsonData = {
      'namespace': Constants.NAMSPACES.SDK,
      'type': Constants.SDK_SERVICE_TYPES.BI_METABASE,
      'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE
    };

    var msg = "\n\n Are you sure you want to " + actionLabel + " this instance?";
    if (value) {
      axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + metabase_name + '/all', jsonData)
        .then(res => {
          swal('Instance ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      swal({
        text: msg,
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + metabase_name + '/all', jsonData)
            .then(res => {
              swal('Instance ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      })
    }
  };


  const byteToMegaByte=(byteValue)=>{
   
    let convertByteToMegabyte=byteValue/1024**2;
    return convertByteToMegabyte;
  }


  const getMetabase = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
  client.get('/sdk/metabase/all')
      .then(res => {
        let data = res.data.data;
        if(Object.keys(res.data.data.stacks).length === 0) {
          setEditPopup(false);
        }
        let sortedData = [];
        if('stacks' in data){
          Object.keys(data.stacks).map((stackName) => {
            let stackData = data.stacks[stackName];
            stackData.name = stackName;
            if(stackData['services']['rdb1']['host_cache_size'] === 'auto') {
              stackData['services']['metabase1']['hosts'] = 'auto';
              stackData['services']['metabase1']['host_cache_size'] = 'auto';
            } else {
              stackData['services']['metabase1']['hosts'] = 'custom';
              stackData['services']['metabase1']['host_cache_size'] = stackData['services']['rdb1']['host_cache_size'];
            }
            stackData['services']['metabase1']['innodb_buffer_pool_size'] = byteToMegaByte(stackData['services']['rdb1']['innodb_buffer_pool_size']);
            stackData['services']['metabase1']['metadata_locks_cache_size'] = stackData['services']['rdb1']['metadata_locks_cache_size'];
            sortedData.push(stackData);
            return stackName;
          });
          sortedData.sort(function(a,b){
            const dateFormatB = b.created_time.split(' ');
            const dateFormatA = a.created_time.split(' ');
            // let dateA = dateFormatA[0] + ' ' + dateFormatA[1];
            // let dateB = dateFormatB[0] + ' ' + dateFormatB[1];
            return dateFormatB - dateFormatA;
          });
        }
        data["sorted_list"] = sortedData;
        if(Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].name;
          let instData = Object.assign({}, data.stacks[name].services.metabase1);
          instData.instance_id = name;
          instData.nickname = data.sorted_list[0].nickname;
          setSelectedInstance(instData);
          setEditPopup(true);
        }

        client.get('/sdk/metabase/status/all')
          .then(res => {
            let metaStatusData = res.data.data;
            if ('stacks' in metaStatusData) {
              let metaStatusList = metaStatusData.stacks;
              Object.keys(metaStatusList).map((mName) => {
                if (mName in data.stacks) {
                  data.stacks[mName].status = metaStatusList[mName];
                }
                return mName;
              });
              setMetabaseData(data);
            } else {
              setMetabaseData(data);
            }
            setLoading(false);
            setOnRefresh(false);
          })
          .catch(err => {
            console.log(err);
            setMetabaseData(data);
            setLoading(false);
            setOnRefresh(false);
          });
      }).catch(error => {
        console.log(error);
        setMetabaseData([]);
        setLoading(false);
      });
  }, []);

  function getDisplayLabel(data, key) {
    return (data[key] !== undefined ? data[key] : key);
  }

  const refreshInstances = (event) => {
    setLoading(true);
    setOnRefresh(true);
    document.getElementById('btnRefreshInstance').textContent = 'Refreshing...';
    getMetabase();
  };

  useEffect(() => {
    if (document.getElementById('btnRefreshInstance')) {
      document.getElementById('btnRefreshInstance').textContent = 'Refresh';
    }
  }, [metabaseData]);

  useEffect(() => {
    generateDeviceRam();
    getMetabase();
  }, [getMetabase, generateDeviceRam]);

  const getClockStatus = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    client.get('/system/clock/status/all')
    .then(res => {
      let data=res.data.data.timezone;
      let splitTimeZone = data.split(' (');
      setCurTimeZone(splitTimeZone[0])
      setStandardTZ(splitTimeZone[1])
      let splitZone = splitTimeZone[1].split(',');
      setStandardTZ(splitZone[0])
    }).catch(error => {
      console.error('clock data');
    });
  },[]);

  useEffect(()=>{
    getClockStatus()
  },[])

  const getDateFormate = (Date,CurTimezone) => {     
    try {
      const zonedTargetDate = convertToTimeZone(Date, { timeZone: CurTimezone });
      return zonedTargetDate;
    } catch (err) {
      console.error(err);
      // return new Date(Date);
    }
  };

  return (
    <ContentDiv>
      { (metabaseData === undefined) &&
        <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress />
        </div>
      }
      {metabaseData &&
        <Wrapper style={{ 'padding': '20px' }}>
          <SectionHeader>
            <SectionLabel>Metabase</SectionLabel>
          </SectionHeader>
          <Row>
            <ActionButtonContainer>
              { metabaseData.stacks && Object.keys(metabaseData.stacks).length > 0 &&
                <Button primary id="btnEditInstance" name="btnEditInstance" onClick={() => editInstance()}>Edit</Button>
              }
              {metabaseData.stacks && Object.keys(metabaseData.stacks).length > 0 &&
                <Button primary id="btnRefreshInstance" name="btnRefreshInstance" disabled={loading} onClick={() => refreshInstances()}>Refresh</Button>
              }
              <Button primary id="btnAddInstance" name="btnAddInstance" onClick={() => addNewInstance()}>Add</Button>
              { modalOpen &&
                <ResponsivePopupContainer>
                  <Popup modal closeOnDocumentClick={false} open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                      <MetabasePopup isEditPopup={isEditPopup} closePopup={closePopup} selected_metabase={ isEditPopup  ? selectedInstance : undefined} metabaseData={metabaseData} />
                  </Popup>
                </ResponsivePopupContainer>}
            </ActionButtonContainer>
          </Row>
          {metabaseData.stacks && Object.keys(metabaseData.stacks).length > 0 &&
            <TableLayout>
              <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th style={{'width': '50px'}}></Th>
                  <Th style={{'text-align': 'left', 'width':'18%'}}>Nickname <br /> (Instance ID)</Th>
                  <Th>Status</Th>
                  <Th>Created</Th>
                  <Th>Dashboard</Th>
                  <Th>Enabled</Th>
                </tr>

                { Object.keys(metabaseData.sorted_list).map((index) => {
                  let instName = metabaseData.sorted_list[index].name;
                  let mData = metabaseData.sorted_list[index].services.metabase1;
                  let nickname =  metabaseData.stacks[instName].nickname ?  metabaseData.stacks[instName].nickname : '';
                  let created = metabaseData.stacks[instName].created_time;
                  var newDate = new Date(created*1000);
                  let calculatedDate = getDateFormate(newDate,CurTimezone);
                  if(calculatedDate === undefined){
                    return;
                  }
                  let date = format(new Date(calculatedDate), 'yyyy/MM/dd');
                  let time = format(new Date(calculatedDate), 'HH:mm:ss');
                  let mStatus = 'recovering';
                  if('status' in metabaseData.stacks[instName]){
                    mStatus = metabaseData.stacks[instName].status.running_status.status;
                  }

                  let path = '';
                  if(window.location.pathname.includes('/proxy')) {
                    path = window.location.host + '/proxy/' + basename + '/';
                  } else {
                    path = window.location.host;
                  }

                  return (
                    <tr>
                      <Td><input type="radio" id="select_radio" style={{ 'cursor': 'pointer' }} defaultChecked={index==='0'} name="select_radio" value={mData.instance_id} onClick={() => radioButtonOnChange(mData.instance_id)}></input></Td>
                      <Td>
                        <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                            <div style={nickNameStyle} title={nickname}>{nickname}</div>
                            <div style={instNameStyle} title={instName}>({instName + ')'}</div>
                        </div>
                      </Td>
                      <Td>
                        {mStatus === '-' ?
                          '-' :
                          <label className={mStatus + '-sdk'}>
                            {getDisplayLabel(Constants.STATUS_TYPES, mStatus)}
                          </label>}
                      </Td>
                      <Td>{date} {time} {standaredTZ}</Td>
                      <Td>
                        <OnRefreshContext.Provider value={{ onRefresh }}>
                          <MetabaseLink port= {mData.port} path={path} enabled={metabaseData.stacks[instName].enabled}/>  
                        </OnRefreshContext.Provider>
                      </Td>
                      <Td><SelectionControl type="switch" id={mData.instance_id} name={mData.instance_id} value={metabaseData.stacks[instName].enabled} checked={metabaseData.stacks[instName].enabled} labelBefore="true" onChange={enableToggle} style={{ 'display': 'block', 'paddingTop': '10px' }} /></Td>
                    </tr>
                  );
                })}
              </table>
            </TableLayout>
          }
          {metabaseData.stacks && Object.keys(metabaseData.stacks).length === 0 &&
            <CenterDiv>
              <Label>No instance found.</Label>
            </CenterDiv>
          }
        </Wrapper>}

    </ContentDiv>
  );
};

function getDefaultMetaBaseData() {
  let defaultMetabaseData = Constants.METABASE_DEFAULT_VALUE;
  defaultMetabaseData.innodb_buffer_pool_size = generateDeviceRam();
  return defaultMetabaseData;
}

const MetabasePopup = (props) => {
  const { isEditPopup, closePopup, selected_metabase, metabaseData } = props;
  const [metabaseSettings, setMetabaseSettings] = useState(selected_metabase === undefined ? getDefaultMetaBaseData() : selected_metabase);

  useEffect(()=>{
    if(metabaseSettings.instance_id === ''){
      if(metabaseData.sorted_list.length !== undefined){
        setMetabaseSettings({...metabaseSettings, instance_id:'metabase'+(metabaseData.sorted_list.length+1)});
      } else {
        setMetabaseSettings({...metabaseSettings, instance_id:'metabase1'});
      }
    }
  },[]);


  const handleOnChange = (event) => {
    if(event.target.name === 'instance_id'){
      if (event.target.value.charAt(0) === '_') {
        return event.target.value.replace(/[^\w]/g, '');
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      } 
    } else if(event.target.name === 'nickname') {
      if (event.target.value.charAt(0) === ' ') {
        return event.target.value.replace(/[^\w]/g, '');
      }
    } else if ( event.target.name === 'metadata_locks_cache_size') {     
      event.target.value = event.target.value.replace(/^0/, "")
      event.target.value = event.target.value.replace(/[^\w]/g, '');
      event.target.value = event.target.value.replace(/[^\d.-]/g, '');
    } else if ((event.target.name === 'host_cache_size') || (event.target.name === 'metadata_locks_cache_size') || (event.target.name === 'innodb_buffer_pool_size') ) {     
      event.target.value = event.target.value.replace(/[^\w]/g, '');
      event.target.value = event.target.value.replace(/[^\d.-]/g, '');
    }
    setMetabaseSettings({ ...metabaseSettings, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event) => {
    event.persist();
    if(event.target.name === 'hosts') {
      let data = {...metabaseSettings}
      if(event.target.value === 'auto') {
        data.host_cache_size = 'auto';
        data.hosts = 'auto'
      } else if( event.target.value === 'custom')  {
        data.host_cache_size = '';
        data.hosts = 'custom'
      }
      setMetabaseSettings(data);
    } else {
      setMetabaseSettings({ ...metabaseSettings, [event.target.name]: event.target.value });
    } 
  };

  const megaByteToByte=(megaByteValue)=>{
    let convertMBtoByte=megaByteValue*1024**2
    return convertMBtoByte;
  }

  const saveInstance = (event) => {
    let jsonData = Object.assign({}, metabaseSettings);
    let metabaseName = jsonData.instance_id;
    let action = isEditPopup ? 'updated' : 'added';
    if(jsonData.hosts === 'auto') {
      jsonData.host_cache_size = 'auto';
    } else {
      jsonData.host_cache_size = jsonData.host_cache_size;
    }

    if (isEmpty(metabaseName)) {
      swal('Please enter instance id.', { icon: 'error' });
      return;
    }

    if (jsonData.host_cache_size === '' && (jsonData.hosts === 'custom')) {
      swal('Please enter number of hosts.', { icon: 'error' });
      return;
    }
    if (jsonData.metadata_locks_cache_size === '') {
      swal('Please enter metadata locks cache size.', { icon: 'error' });
      return;
    }
    if (jsonData.innodb_buffer_pool_size === '') {
      swal('Please enter innodb buffer pool size.', { icon: 'error' });
      return;
    }
 
    if (jsonData.host_cache_size > 65536) {
      swal('Please enter number of hosts less than or equal to 65536', { icon: 'error' });
      return;
    }
    if (jsonData.metadata_locks_cache_size > 1048576) {
      swal('Please enter metadata locks cache size less than or equal to 1048576', { icon: 'error' });
      return;
    }
    if (parseInt(jsonData.innodb_buffer_pool_size) > 10240) {
      swal('Please enter innodb buffer pool size less than or equal to 10240.', { icon: 'error' });
      return;
    }
    if (parseInt(jsonData.innodb_buffer_pool_size) < 1) {
      swal('Please enter innodb buffer pool size more than or equal to 1.', { icon: 'error' });
      return;
    }
    
    let rdbJson = Constants.RDB_SERVICE_VALUE;
    rdbJson.instance_id = jsonData.instance_id;
    rdbJson.innodb_buffer_pool_size = generateDeviceRam();
    let finalJson = {};
    let innoDb=megaByteToByte(jsonData.innodb_buffer_pool_size)
    finalJson.nickname = jsonData.nickname;
    finalJson.enabled =  metabaseData.stacks[metabaseName] === undefined ? true : metabaseData.stacks[metabaseName].enabled;
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = Constants.SDK_SERVICE_TYPES.BI_METABASE;
    finalJson.services = {};
    delete jsonData.nickname;
    delete jsonData.hosts;
    finalJson.services.metabase1 = Object.assign({}, jsonData);
    delete finalJson.services.metabase1.innodb_buffer_pool_size;
    delete finalJson.services.metabase1.metadata_locks_cache_size;
    delete finalJson.services.metabase1.host_cache_size;
    finalJson.services.rdb1 = Object.assign({}, rdbJson);
    finalJson.services.rdb1.innodb_buffer_pool_size=innoDb.toString();
    finalJson.services.rdb1.metadata_locks_cache_size=jsonData.metadata_locks_cache_size;
    finalJson.services.rdb1.host_cache_size=jsonData.host_cache_size;

    if (action === 'added') {
      axios.put(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + metabaseName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      axios.patch(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + metabaseName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    }
  };

  const deleteMetabase = (event) => {
    let metabaseName = metabaseSettings.instance_id;
    swal({
      text: 'Are you sure you want to delete this instance: ' + metabaseName + '?',
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
      .then((isConfirm) => {
        if (isConfirm) {
          axios.delete(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + metabaseName + '/all')
            .then(res => {
              swal('Instance delete request submitted successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      });

  };

  return (
    <div>
      {metabaseSettings !== undefined &&
        <PopupContainer>
          <SectionHeaderMetabase style={{ 'display': 'flex' }}>
            <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Instance</SectionLabel>
            {isEditPopup &&
              <div style={{ 'marginLeft': 'auto' }}>
                <Button danger id="btnDeleteInstance" name="btnDeleteInstance" style={{ 'margin': '0px' }} onClick={deleteMetabase} >Delete</Button>
              </div>
            }
          </SectionHeaderMetabase>
          <ResponsiveTabs defaultTab="general" style={{ 'padding': '6px' }}>
            <TabList>
              <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
              <Tab tabFor="advanced" className={'rwt__tab'}>Advanced</Tab>
            </TabList>
            <TabPanel tabId="general">
              <ResponsiveTabContent>
                <Row>
                  <ResponsiveMandatoryLabelGeneral>Instance ID</ResponsiveMandatoryLabelGeneral>
                  <ResponsiveTextFieldSmall id="instance_id" autoComplete="off" name="instance_id" value={metabaseSettings.instance_id} onChange={handleOnChange} disabled={isEditPopup} maxLength="63" />
                </Row>
                <Row>
                  <ResponsiveTextFieldLabel>Nickname</ResponsiveTextFieldLabel>
                  <ResponsiveTextFieldSmall id="nickname" autoComplete="off" name="nickname" value={metabaseSettings.nickname} onChange={handleOnChange} maxLength="100" />
                </Row>
              </ResponsiveTabContent>
            </TabPanel>
            <TabPanel tabId='advanced'>
              <ResponsiveTabContent>

                  <ResponsiveRow1>
                  <MandatoryLabel>Host Cache Size</MandatoryLabel>
                  <div style={{'display':'flex', 'flexDirection': 'column'}}>
                    <SelectField id="hosts" name="hosts" sty value={metabaseSettings.hosts} onChange={handleSelectChange}>
                      <option value='auto'>auto</option>
                      <option value='custom'>Custom</option>
                    </SelectField>
                  </div>
                  </ResponsiveRow1>
                  {metabaseSettings.hosts==='custom' && 
                  <ResponsiveRow2>
                    <MandatoryLabel>Number Of Hosts</MandatoryLabel>
                    <TextFieldSmall id="host_cache_size" autoComplete="off" maxLength='5' name="host_cache_size" value={metabaseSettings.host_cache_size} onChange={handleOnChange} style={{'marginTop': '20px'}} />
                  </ResponsiveRow2>
                  }
               
                <ResponsiveRow>
                  <MandatoryLabel>Metadata Locks Cache Size (Bytes)</MandatoryLabel>
                  <TextFieldSmall id="metadata_locks_cache_size" autoComplete="off" maxLength='7' name="metadata_locks_cache_size" value={metabaseSettings.metadata_locks_cache_size} onChange={handleOnChange} />
                </ResponsiveRow>
                <ResponsiveRow>
                  <MandatoryLabel>Innodb Buffer Pool Size (MB)</MandatoryLabel>
                  <TextFieldSmall id="innodb_buffer_pool_size" autoComplete="off"  maxLength='5' name="innodb_buffer_pool_size" value={metabaseSettings.innodb_buffer_pool_size} onChange={handleOnChange} />
                </ResponsiveRow>
              </ResponsiveTabContent>
            </TabPanel>
            </ResponsiveTabs>
          <Row>
            <PopupButtons>
              <Button onClick={saveInstance} primary >Save</Button>
              <Button id="btnCancelInstance" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row>
        </PopupContainer>
      }
    </div>
  );
};

export default Metabase;
