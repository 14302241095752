import React, { useState, useEffect,useCallback } from 'react';
import axios from 'axios';
import { ContentDiv, Wrapper, Label, Row, Column, SectionHeader, SectionLabel, MessageLabel, DeviceInfoOne, DeviceInfoTwo, DeviceInfoContainer,Td,TableContainer } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { CircularProgress } from 'react-md';
import swal from 'sweetalert';
import ls from 'local-storage';
import styled from 'styled-components';
import format from 'date-fns/format';
import { formatDistance } from 'date-fns';
import {convertToTimeZone} from 'date-fns-timezone';
import '../css/DeviceInfo.css';
import axiosRetry from 'axios-retry';


const MessageContaier = styled.div`
  padding: 5px 5px 5px 10px;
  font-size: 0.9em;
`;
const DiskDiv=styled.div`
@media screen and (max-width: 450px) {
  margin-top:-6px;
}
`;

const Column1 = styled.div`
  float:left;
  padding: 10px 0 0 0px;
`;

const ReleseDiv = styled.div`
  padding: 0px 0 0 12px;
`;

const ReleaseColumn = styled(Column)`
  width : 35%;
  @media screen and (max-width: 500px) {
  width : 33%;
}
  @media screen and (max-width: 400px) {
  width : 32%;
}
`;

const TH = styled.th`
  // text-align:center;
  padding-left: 7px;
`;

const TD =styled.td`
  padding:7px 8px;
`;

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
  margin-top: 20px;
`;

const DeviceInfo = (props) => {
  const device_info_lable = Constants.DEVICE_INFO_LABLE;
  const [deviceSystemInfo, setDeviceSystemInfo] = useState();
  const [timezone, setTimeZone] = useState('');
  const [usbDevices, setUsbDevices] = useState([]);
  const [isUsbDevicesLoading, setIsUsbDevicesLoading] = useState(false);
  const [uploadedTime, setUploadedTime] = useState();
  const [vpnStatus, setVpnStatus] = useState();
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const [retry,setRetry]=useState(false)
  const [displayPrev, setDisplayPrev] = useState({});
  const [displayState, setDisplayState] = useState({});
  const [gitHash, setGitHash]=useState('');
  const [manufacturingDate, setManufacturingDate] = useState('');
  const [deviceId, setDeviceID]= useState('');
  const [deleteUserVpn, setDeleteUserVpn]= useState();

  const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
  axiosRetry(client,{
    retries: 15,
    retryDelay: (retryCount, error) => {
      if(retryCount < 15 && retryCount > 5) {
        setRetry(true)
     } else if(retryCount === 15) {
       setRetry(false)
       swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
     }
        return 3000;
    },
    retryCondition: (error) => {
        return true;
    },
  });

  const usbClient = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
  axiosRetry(usbClient,{
    retries: 15,
    retryDelay: (retryCount, error) => {
      if(retryCount < 15 && retryCount > 5) {
        setRetry(true)
     } else if(retryCount === 1) {
       setRetry(false)
       setIsUsbDevicesLoading(true)
     }
        return 3000;
    },
    retryCondition: (error) => {
        return true;
    },
  });

  useEffect(()=>{
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  },[]);

  useEffect(() => {
    client.get('/system/network/status')
    .then(res => {
      setVpnStatus(res.data.data);
    })
    .catch(error => {
      console.log(error);
    });
  },[]);

  useEffect(() => {
    client.get('/devices/deviceinfo')
      .then(res => {
        var systemInfo = res.data;
        setDeviceID(systemInfo.data.device_id)
        setManufacturingDate(!systemInfo.data.software.mfg_date ? null : systemInfo.data.software.mfg_date);
        setGitHash(!systemInfo.data.software.last_commit_hash ? null :(systemInfo.data.software.last_commit_hash).substr(0,8));
        setDeviceSystemInfo(systemInfo.data);
        if('device_id' in res.headers){
          ls.set('device_id', res.headers.device_id);
          // document.getElementById('deviceId').textContent = res.headers.device_id;
        }
        let interfaces = systemInfo.data.network.interfaces;
        let eno1, eth0, wlan0;
        Object.entries(interfaces).map(([key,value])=>{
          if(value.interface === 'eno1.inet'){
            eno1 = value.ip_address.split('/')[0];
          }
          if(value.interface === 'eth0.inet'){
            eth0 = value.ip_address.split('/')[0];
          }
          if(value.interface === 'wlan0.inet'){
            wlan0 = value.ip_address.split('/')[0];
          }
          return value;
        });
        if(eno1 !== undefined){
          ls.set('device_ip', eno1);
        } else if(eth0 !== undefined){
          ls.set('device_ip', eth0);
        } else if(wlan0 !== undefined){
          ls.set('device_ip', wlan0);
        }
      })
      .catch(error => {
        if(error.response){
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
        }else{
          swal(error.toString(), { icon: 'error'});
        }
      });

  }, []);

  useEffect(() => {
    setIsUsbDevicesLoading(true);
    usbClient.get('/system/usb/all')
      .then(res => {
        setUsbDevices(res.data.data);
        setIsUsbDevicesLoading(false);
      })
      .catch(error => {
        setIsUsbDevicesLoading(false);
        console.log(error);
      });
  }, []);

  const getVPNData = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/vpn/vpn_client/all')
      .then(res => {
        if (res.data.data.action === 'DeleteStack') {
          setDeleteUserVpn(true);
        } else {
          setDeleteUserVpn(false);
        }
      }).catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getVPNData();
  }, []);

  useEffect(() => {
    client.get('/system/system_services/uploader/status')
    .then(res => {
      let keys =[];
      const tz = res.data.data. env_variables[0].split('=');
      setTimeZone(tz[1]);
      const dateArray = Object.entries(res.data.data.health_status.status_detail.last_upload_time);
      dateArray.forEach(([key, value]) => {
        keys.push(value);
      });
      setUploadedTime(keys);
    }).catch(error => {
      if (error.response) {
        var errorObj = error.response.data;
        swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
      } else {
        swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
      }
    });
  },[]);

  const convertedArrayDate = () => {
    try {
      let newDates = [];
      uploadedTime.forEach(date => {
        if(date === '0' || date === '') {
          return
        }
      const splitDate = date.split(' ');
      let dateFormat = splitDate[0] + ' ' + splitDate[1];
      var d1 = new Date(dateFormat);
      newDates.push(d1);
    });
    var maxDatenew = Math.max.apply(null, newDates)
    maxDatenew = new Date(maxDatenew)
    const maxDateformatted = format(maxDatenew, "yyyy/MM/dd HH:mm:ss")
    let returnDate = '';
    uploadedTime.forEach(date => {
      const splitDate = date.split(' ');
      const mydate = splitDate[0] + ' ' + splitDate[1];

      if(maxDateformatted === mydate) {
        returnDate = date;
      }
    })
    return returnDate;

    } catch (err) {
      console.log(err);
    }
    return '-';
  }

  const getTimeSincerrayDate = () => {
    try{
      let newDates = [];
      uploadedTime.forEach(date => {
        if(date === '0' || date === '') {
          return '';
        }
        const splitDate = date.split(' ');
        let dateFormat = splitDate[0] + ' ' + splitDate[1];
        var d1 = new Date(dateFormat);
        newDates.push(d1);
    });
    const maxDate = new Date(Math.max.apply(null, newDates));
    const zonedTargetDate = convertToTimeZone(new Date(), { timeZone: timezone });
    const result = formatDistance(
      new Date(maxDate), zonedTargetDate,
      { addSuffix: true, includeSeconds: true }
    );
      return ' (' + result + ')';
    } catch (err) {
      console.log(err);
    }
    return '';
  };

  const getTimeSince = (date) => {
    if(date === '0') {
      return '';
    } else {
      try {
        if (date) {
          var newStr = date.split(' ');
          let formattedDate = newStr[0] + ' ' + newStr[1];
          const result = formatDistance(
            new Date(formattedDate), new Date(),
            { addSuffix: true, includeSeconds: true }
          );
          return ' (' + result + ')';
        }
      } catch (err) {
        console.log(err);
      }
      return '';
    }
  };

  const getDisplayData = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/services/display/left_menu/all')
    .then(res => {
      let data = res.data.data;
      if("device_id" in res.headers){
        data.device_id = res.headers["device_id"];
      }
      setDisplayPrev(data);
      setDisplayState(data);
    })
    .catch(error => {
      console.error(error);
    });
  },[]);

    useEffect(() => {
      getDisplayData();
    },[getDisplayData]);

  return (
    <ContentDiv style={{'overflow':'hidden'}}>
      { deviceSystemInfo === undefined &&
        <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress/>
        </div>
      }
      {deviceSystemInfo &&
      <div>
        <Wrapper>
          <SectionHeader>
            <SectionLabel>Device Information</SectionLabel>
          </SectionHeader>
          <DeviceInfoContainer className='deviceInfoContainer'>
            <div className='deviceInfoOne'>
              <fieldset>
                <legend>
                  System
                </legend>
                {deviceSystemInfo &&
                Object.entries(deviceSystemInfo.system).map(([key,value])=>{
                  if(key !== 'disk'){
                    if (key === 'ram'){
                      value = value.indexOf(',') !== -1 ? value.substr(7, value.indexOf(',')-7) : value;
                      var formatedValue = Number(parseFloat(value.replace('MB','')).toFixed(0)).toLocaleString('en', {minimumFractionDigits: 0}) + ' MB';
                      return(
                        <Row>
                          <div><Column style={{'width':'35%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column></div>
                          <div><Column style={{'width':'65%'}} id={key} >{formatedValue}</Column></div>
                        </Row>
                      );
                    }
                    if (key === 'cpu'){
                      return(
                        <Row>
                          <div><Column style={{'width':'35%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column></div>
                          <div><Column style={{'width':'65%'}} id={key} >{value}</Column></div>
                        </Row>
                      );
                    }
                    if (key === 'graphics_card') {
                      return(
                        <Row>
                          <div><Column style={{'width':'35%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column></div>
                          <div><Column style={{'width':'65%'}} id={key} >
                            { deviceSystemInfo.system.graphics_card.map(val=>{
                              return(
                                <Row>
                                  {val ? val : 'Not Available'}
                                </Row>
                              );})
                            }
                          </Column></div>
                        </Row>
                      );
                    }
                  }
                  else{
                    return(
                      <Row>
                        <div><Column style={{'width':'35%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column></div>
                        <div><Column style={{'width':'65%'}}>
                          <DiskDiv>
                          {
                            <table style={{'width':'100%'}}>
                              <tr style={{'line-height': '18px'}}>
                                <th>Type</th>
                                <th>Total</th>
                                <th>Used</th>
                                <th>Free</th>
                              </tr>
                              { Object.entries(deviceSystemInfo.system.disk).map(([key1,value1])=>{
                                return(
                                  <tr style={{'line-height': '12px'}}>
                                    <td style={{'padding':'7px 0px'}}>{value1.type}</td>
                                    <td style={{'padding':'7px 0px'}}>{value1.total}</td>
                                    <td style={{'padding':'7px 0px'}}>{value1.used}</td>
                                    <td style={{'padding':'7px 0px'}}>{value1.free}</td>
                                  </tr>
                                );
                              })
                              }
                            </table>
                          }
                          </DiskDiv>
                        </Column></div>
                      </Row>
                    );
                  }
                  return value;
                })
                }
              </fieldset>
                {
                  deviceSystemInfo.raid_info &&  
                    <fieldset>
                      <legend>
                      Disk Array Controller
                      </legend>
                      {
                        Object.entries(deviceSystemInfo.raid_info).map(([key, value]) => {
                            if (key === 'model_status') {
                              return (
                                <Row>
                                  <div><Column style={{ 'width': '35%' }}><b>{key in device_info_lable ? device_info_lable[key] : key}</b></Column></div>
                                  <div><Column style={{ 'width': '65%' }} id={key} >{value}</Column></div>
                                </Row>
                              );
                            }

                            if (key === 'virtual_drives') {
                              return (
                                <Row>
                                  <div><Column style={{ 'width': '100%' }}>
                                    <div style={{ 'overflow-x': 'auto', 'white-space': 'nowrap' }}>
                                      {
                                        <table style={{ 'width': '100%' }}>
                                          <tr style={{ 'line-height': '18px' }}>
                                            <th>VG</th>
                                            <TH>Name</TH>
                                            <TH>Type</TH>
                                            <TH>Size</TH>
                                            <TH>Status</TH>
                                          </tr>
                                          {Object.entries(deviceSystemInfo.raid_info.virtual_drives).map(([key1, value1]) => {
                                            let splitVgValue = value1.vg.split('/')[1]
                                            return (
                                              <tr style={{ 'line-height': '12px' }}>
                                                <td>{splitVgValue ? splitVgValue : '-'}</td>
                                                <TD>{value1.name ? value1.name : '-'}</TD>
                                                <TD>{value1.type ? value1.type : '-'}</TD>
                                                <TD>{value1.size ? value1.size : '-'}</TD>
                                                <TD>{value1.status ? value1.status : '-'}</TD>
                                              </tr>
                                            );
                                          })
                                          }
                                        </table>
                                      }
                                    </div>
                                  </Column></div>
                                </Row>
                              );
                            }

                            if(key === 'physical_drives'){
                              return (
                                <Row>
                                  {/* <Column style={{ 'width': '35%' }}><b>{key in device_info_lable ? device_info_lable[key] : key}</b></Column> */}
                                  <div><Column style={{ 'width': '100%' }}>
                                    <div style={{ 'overflow-x': 'auto', 'white-space': 'nowrap' }}>
                                      {
                                        <table  style={{ 'width': '100%' }}>
                                          <tr style={{ 'line-height': '18px' }}>
                                            <th>Enc:Slot</th>
                                            <TH>Model</TH>
                                            <TH>Size</TH>
                                            <TH>Status</TH>
                                          </tr>
                                          {Object.entries(deviceSystemInfo.raid_info.physical_drives ).map(([key1, value1]) => {
                                            return (
                                              <tr style={{ 'line-height': '12px' }}>
                                                <td>{value1['enc_slot'] ? value1['enc_slot'] : '-'}</td>
                                                <TD>{value1.model ? value1.model : '-'}</TD>
                                                <TD>{value1.size ? value1.size : '-'}</TD>
                                                <TD>{value1.status ? value1.status : '-'}</TD>
                                              </tr>
                                            );
                                          })
                                          }
                                        </table>
                                      }
                                    </div>
                                  </Column></div>
                                </Row>
                              );
                            }
                        })
                      }
                    </fieldset>
                }
                    <fieldset>
                <legend>
                  Network
                </legend>
                {deviceSystemInfo &&
                  Object.entries(deviceSystemInfo.network).map(([key,value])=>{
                    if(key !== 'internet connectivity'){
                      return(
                        <Row style={{'overflow-x': 'auto', 'white-space': 'nowrap'}}>
                          <div><Column style={{'width':'100%'}}>
                            <table style={{'width':'100%'}}>
                              <tr  style={{'line-height': '18px'}}>
                                <th>Interface</th>
                                <th>IP Address</th>
                                <th>MAC Address</th>
                              </tr>
                              {
                                Object.entries(deviceSystemInfo.network.interfaces).map(([key1,value1])=>{
                                  if ((value1.interface.startsWith('br-') || value1.interface.startsWith('docker') || value1.interface.startsWith('veth')) === false) {
                                    let inetIndex = value1.interface.indexOf('.inet');
                                    let infName = inetIndex !== -1 ? value1.interface.substr(0, inetIndex) : value1.interface;
                                    return(
                                      <tr  style={{'line-height': '12px'}}>
                                        <td style={{'padding':'7px 0px'}} id="interface">{value1.is_ap ? '[AP] ' : ''}{infName}</td>
                                        <td style={{'padding':'7px 0px'}} id="ip_address">{value1.ip_address}</td>
                                        <td style={{'padding':'7px 0px'}} id="mac_address">{value1.mac_address}</td>
                                      </tr>
                                    );
                                  }
                                  return '';
                                })
                              }
                            </table>
                          </Column></div>
                        </Row>
                      );
                    }
                    return '';
                  })
                }
              </fieldset>
                      <fieldset>
                        <legend>
                          USB Devices
                        </legend>
                        <div style={{'overflow-x': 'auto', 'white-space': 'nowrap'}}>
                  { isUsbDevicesLoading ?
                    <Label>Fetching USB devices information...</Label>
                    :
                    <table style={{'width':'100%'}}>
                      <tr  style={{'line-height': '11px'}}>
                        <th style={{'padding':'7px 10px 7px 5px'}}>Bus ID</th>
                        <th style={{'padding':'7px 10px 7px 5px'}}>Port ID</th>
                        <th style={{'padding':'7px 10px 7px 5px'}}>Type</th>
                        {/*<th style={{"padding":"7px 10px 7px 5px"}}>Name</th>*/}
                        <th style={{'padding':'7px 10px 7px 5px'}}>Description</th>
                      </tr>
                      {
                        usbDevices.length === 0 ?
                          <tr>
                            <td colSpan="5"><Label style={{'margin-top': '10px'}}>USB devices not found</Label></td>
                          </tr>
                          : usbDevices.map((device) => {
                            return(
                              <tr  style={{'line-height': '12px'}}>
                                <td style={{'padding':'7px 10px 7px 5px'}} id="device_bus_id">{device.bus_id}</td>
                                <td style={{'padding':'7px 10px 7px 5px'}} id="device_port_id">{device.port_id}</td>
                                <td style={{'padding':'7px 10px 7px 5px'}} id="device_type">{device.type}</td>
                                {/*<td style={{"padding":"7px 10px 7px 5px"}} id="device_name">{device.name}</td>*/}
                                <td style={{'padding':'7px 10px 7px 5px'}} id="device_description">{device.description}</td>
                                      </tr>
                                    );
                                  })
                              }
                            </table>
                          }
                        </div>
                      </fieldset>
                    </div>
            <div className='deviceInfoTwo'>
              <fieldset>
                <legend>
                  Uptime
                </legend>
                {deviceSystemInfo &&
                  Object.entries(deviceSystemInfo.device_uptime).map(([key,value])=>{
                    return(
                      <Row>
                        <div><Column style={{'width':'35%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column></div> 
                        <div><Column style={{'width':'65%', display: 'inline-block'}} id={key}> {key==='last_boot' ? ((value === '' && value === '0') ? '-' : value ): value}</Column></div>
                      </Row>
                    );
                  })
                }
              </fieldset>
              {/*  */}
              <fieldset>
                <legend>
                  SCORER Edge
                </legend>
                <Row style={{'overflow-x': 'auto'}}>
                  <div><Column style={{'width':'35%'}}><b>Device ID</b></Column></div>
                  <div><Column style={{'width':'65%', display: 'inline-block'}}>{deviceId}</Column></div>
                </Row>
                <Row style={{'overflow-x': 'auto'}}>
                  <div><Column style={{'width':'35%'}}><b>Manufacture Date</b></Column></div>
                  <div><Column style={{'width':'65%', display: 'inline-block'}}>{manufacturingDate}</Column></div>
                </Row>
                {deviceSystemInfo &&
                  Object.entries(deviceSystemInfo.software).map(([key,value])=>{
                    if (key === "scorer_release") {
                      return (
                        <Row>
                          <div><Column style={{ 'width': '35%' }}><b>{key in device_info_lable ? device_info_lable[key] : key}</b></Column></div>
                          <div><Column style={{ 'width': '65%' }} id={key}>{!gitHash || gitHash === '' ? null : value.replace(/-/g, '/')} ({gitHash})</Column></div>
                        </Row>
                      )
                    };
                  })
                }
              </fieldset>
              <fieldset>
                <legend>
                  SCORER Cloud
                </legend>
                <Row style={{'overflow-x': 'auto', 'white-space': 'nowrap'}}>
                  {deviceSystemInfo &&
                    <>
                    <Row>
                      <div><Column style={{'width':'35%'}}><b>Status</b></Column></div>
                      <div><Column style={{'width':'65%', display: 'inline-block'}} >{deviceSystemInfo.scorer_cloud.status}</Column></div>
                    </Row>
                    {(deviceSystemInfo.scorer_cloud.status === 'Connected') &&
                    <Row>
                      <div><Column style={{'width':'35%'}}><b>{deviceSystemInfo.scorer_cloud.status === 'Connected' && 'Last Upload' }</b></Column></div>
                      <div><Column style={{'width':'65%', display: 'inline-block'}} >{convertedArrayDate() + ' ' + getTimeSincerrayDate()}</Column></div>
                    </Row>
                    }
                    {(deviceSystemInfo.scorer_cloud.status === 'Disconnected') &&
                    <Row>
                        <div><Column style={{'width':'35%'}}><b>{deviceSystemInfo.scorer_cloud.status === 'Disconnected' && 'Last Connected' }</b></Column></div>
                        <div><Column style={{'width':'65%', display: 'inline-block'}}>{deviceSystemInfo.scorer_cloud.shadow_last_update_time !== '' ? ( ((deviceSystemInfo.scorer_cloud.shadow_last_update_time === '' && deviceSystemInfo.scorer_cloud.shadow_last_update_time === '0') ? '-' : deviceSystemInfo.scorer_cloud.shadow_last_update_time )) + getTimeSince(deviceSystemInfo.scorer_cloud.shadow_last_update_time) : '-'}</Column></div>
                    </Row>}
                    </>
                  }
                  <Row>
                    <MessageContaier>
                      <MessageLabel>
                        <b>Connected:</b> This device is communicating with SCORER Cloud.<br/>
                        <b>Disconnected:</b> This device couldn't connect to SCORER Cloud for 10+ min.<br/>
                        <b>Not Available:</b> This device is not linked (not registered) to SCORER Cloud.<br/>
                      </MessageLabel>
                    </MessageContaier>
                  </Row>
                </Row>
              </fieldset>
              {
                vpnStatus && 
                <fieldset>
                  <legend>
                    VPN
                  </legend>
                  <Row style={{'overflow-x': 'auto'}}>
                      <div><Column style={{'width': '35%', display: 'inline-block'}}><b>Support VPN</b></Column></div>
                      <div><Column style={{'width': '65%', display: 'inline-block'}} >{vpnStatus.vpn_if === undefined || vpnStatus.vpn_if === "" ? ' Not Available' : (vpnStatus.services.ip_addresses[vpnStatus.vpn_if] !==  undefined && vpnStatus.vpn_if ? ' Connected '+ '(' + vpnStatus.vpn_if +')' : ' Disconnected' )}</Column></div>
                    </Row>
                    <Row style={{'overflow-x': 'auto'}}>
                      <div><Column style={{'width': '35%'}}><b>Webgate VPN</b></Column></div>
                      <div><Column style={{'width': '65%', display: 'inline-block'}}>{vpnStatus.vpn_if_webgate === undefined || vpnStatus.vpn_if_webgate === "" ? ' Not Available' : (vpnStatus.services.ip_addresses[vpnStatus.vpn_if_webgate] !== undefined && vpnStatus.vpn_if_webgate ? ' Connected '+ '(' + vpnStatus.vpn_if_webgate +')' : ' Disconnected' )}</Column></div>
                    </Row>
                    <Row style={{'overflow-x': 'auto'}}>
                      <div><Column style={{'width': '35%'}}><b>User-defined VPN</b></Column></div>
                      <div><Column style={{'width': '65%', display: 'inline-block'}}>{vpnStatus.vpn_if_vpnclient === undefined || vpnStatus.vpn_if_vpnclient === "" || deleteUserVpn === true ? ' Not Available' : ((vpnStatus.services.ip_addresses[vpnStatus.vpn_if_vpnclient] !== undefined && vpnStatus.vpn_if_vpnclient) ? ' Connected '+ '(' + vpnStatus.vpn_if_vpnclient +')' : ' Disconnected' )  }</Column></div>
                    </Row>
                    <Row>
                    <MessageContaier>
                      <MessageLabel>
                        <b>Connected:</b> The IP address is assigned.<br/>
                        <b>Disconnected:</b> The endpoint is available but IP address is not assigned yet.<br/>
                        <b>Not Available:</b> The endpoint does not exist.<br/>
                      </MessageLabel>
                    </MessageContaier>
                    </Row>
              </fieldset>}
            </div>
          </DeviceInfoContainer>
        </Wrapper>
      </div>
      }
    </ContentDiv>
  );
};

export default DeviceInfo;
