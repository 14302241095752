import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { SelectionControl, CircularProgress } from 'react-md';
import { Row, Button, SectionHeader, SectionLabel, CopyButton, Link, CenterDiv, RightDiv, TextField, SelectField, TextArea, Label, Td, Th, overlayStyle, TableContainer, PopupButtons, PopupContainer, TextFieldLabel, TextFieldSmall, MandatoryLabel, Link1, LinkIcon, MessageLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { generateRandomPassword, clickToCopy, isEmpty, dateFormat, timeZone } from '../utils/Utils';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import SelectOptions from './SelectOptions';
import ls from 'local-storage';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import axiosRetry from 'axios-retry';
import { ReactComponent as CopySVG } from '../images/copy.svg';
import { convertToTimeZone } from 'date-fns-timezone';
import { format, toDate } from 'date-fns';


const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
`;

const ContentDiv = styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerHost = styled.div`
  display: flex;
  justify-content: center;
`;


const InnerHostContainer = styled.div`
  display: flex;
  margin-right: 3px;
  padding-left: 2px;
`;

const NetworkContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ToggleDiv = styled.div`
  width : 110px;
`;

const SmallTextField = styled(TextField)`
  width: 55px;
`;

const PeriodicRestartInput =styled.div`
  margin-top : 5px;
  flex: none;
  @media screen and (max-width: 1025px) {
    margin-top : 8px;
  }
`;

const InputFieldContainer = styled.div`
  display: flex;
  align-items: baseline;
  & > label {
    width: 150px;
    flex-shrink: 0;
    @media screen and (max-width: 750px) {
      width: 105px;
    }
    @media screen and (max-width: 400px) {
      width: 90px;
    }
  }
`;

const MessageContaier = styled.div`
  font-size : 13px;
  margin-top:-4px;
  padding: 0 5px 1px 15px;
  width: 100%;
  @media screen and (max-width: 950px) {
    width: 80%;
  }
  @media screen and (max-width: 330px) {
    width: 90%;
  }
`;


const ScheduleInputFieldContainer = styled.div`
  display: flex;
  align-items: baseline;
  & > label {
    width: 120px;
    flex-shrink: 0;
    @media screen and (max-width: 750px) {
      width: 105px;
    }
    @media screen and (max-width: 400px) {
      width: 90px;
    }
  }
`;

const InputContainer = styled.div`
  width: 560px;
  padding-left: 20px;
  overflow:auto;
  @media screen and (max-width: 750px) {
    width: 475px;
  }
  @media screen and (max-width: 575px) {
    width: 400px;
  }
  @media screen and (max-width: 480px) {
    width: 330px;
  }
  @media screen and (max-width: 400px) {
    width: 280px;
  }
`;

const ResponsiveWrapper = styled(Wrapper)`
  @media screen and (max-width: 750px) {
    font-size: 0.95rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 550px) {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.68rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 0.65rem;
  }
`;

const CopyButtonHost = styled(CopySVG)`
  height: 18px;
  width: 18px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
  @media screen and (max-width: 500px) {
    height: 18px;
    width: 18px;
    margin: -4px 2px 6px 22px !important;
  }
`;

const SectionHeader1=styled.div`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;
`;

const VolumeTd = styled(Td)`
  width: 155px;
  @media screen and (max-width: 775px) {
  width: 115px;
  }
  @media screen and (max-width: 400px) {
  width: 110px;
  }
`;

const PermissionTd = styled(Td)`
  width: 130px;
  @media screen and (max-width: 775px) {
  width: 112px;
  }
  @media screen and (max-width: 400px) {
  width: 110px;
  }
`;

const ResponsiveRow = styled(Row)`
  display: flex;
  align-items: baseline;
  & > ${TextFieldSmall} {
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${TextFieldSmall}{
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${SelectField} {
    width: 217px;
  }
  & > ${MandatoryLabel} {
    width: 253px;
    flex-shrink: 0;
    @media screen and (max-width: 680px) {
      width: 175px;
    }
  }
`;

const getEnvironmentVariable = () => {
  return {
    'key': '',
    'value': '',
  };
};

const Project = (props) => {
  const [isEditPopup, setEditPopup] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [projectList, setProjectList] = useState()
  const [selectedProject, setSelectedProject] = useState({});
  const [CurTimezone, setCurTimeZone]=useState();
  const [standaredTZ, setStandardTZ]=useState();
  const [volumeList, setVolumeList] = useState();
  const deviceID = ls.get('device_id') === undefined ? '' : ls.get('device_id') + '.local';
  let pathArray = window.location.pathname.split('/');
  const basename = pathArray[2];

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '30%',
    border: '2px solid #d7d7d7'
  };

  const projNameStyle = {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '200px'
  };

  const descriptionStyle = {
    textAlign: 'center',
    padding: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '300px'
  };

  const hostStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '350px'
  };

  const editProject = () => {
    setEditPopup(true);
    setModalOpen(true);
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const addNewProject = (event) => {
    setEditPopup(false);
    // setSelectedProject(undefined);
    setModalOpen(true);
  };

  const radioButtonOnChange = (name) => {
    let data = Object.assign({}, projectList.stacks[name].services.project1);
    data.project_name = name;
    data.description = projectList.stacks[name].description;
    data.nickname = projectList.stacks[name].nickname;
    setSelectedProject(data)
    setEditPopup(true);         
  };

  const enableToggle = (value, event ) => {
    let project_name = event.target.name;
    var actionLabel = value ? "enable" : "disable";

    var jsonData = {
        'namespace': Constants.NAMSPACES.SDK,
        'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE,
        'type': 'project',
    }
    var msg = "\n\n Are you sure you want to " + actionLabel + " this instance?";
    if (value) {
      axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/project/' + project_name + "/all", jsonData)
        .then(res => {
          swal('Instance ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' })
          setTimeout(function () { window.location.reload() }, 2000)
        })
        .catch(error => {
          swal('Error in ' + (value ? 'stop' : 'start') + ' project', { icon: 'error' })
        })
    } else {
      swal({
        text: msg, buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/project/' + project_name + "/all", jsonData)
            .then(res => {
              swal('Instance ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' })
              setTimeout(function () { window.location.reload() }, 2000)
            })
            .catch(error => {
              swal('Error in ' + (value ? 'stop' : 'start') + ' project', { icon: 'error' })
            })
        }
      });
    }
  };

  function getDisplayLabel(data, key) {
    return (data[key] !== undefined ? data[key] : key);
  }

 

  useEffect(() => {
    if (document.getElementById('btnRefreshProject')) {
      document.getElementById('btnRefreshProject').textContent = 'Refresh';
    }
  }, [projectList]);

  const getProjectData = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 4,
      retryDelay: (retryCount, error) => {
          if(retryCount === 4) {
            swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
          }
        return 3000;
      },
      retryCondition: (error) => {
        return true;
      },
    });
    client.get('/sdk/project/all')
    .then(res => {
      let data = res.data.data;
        if(Object.keys(res.data.data.stacks).length === 0) {
          setEditPopup(false);
        } 
        let sortedData = [];
        if('stacks' in data){
          Object.keys(data.stacks).map((stackName) => {
            let stackData = data.stacks[stackName];
            stackData.name = stackName;
            sortedData.push(stackData);
            return stackName;
          });
          sortedData.sort(function(a,b){
            const dateFormatB = b.created_time.split(' ');
            const dateFormatA = a.created_time.split(' ');
            // let dateA = dateFormatA[0] + ' ' + dateFormatA[1];
            // let dateB = dateFormatB[0] + ' ' + dateFormatB[1];
            return dateFormatB - dateFormatA;
          });
        }
        data["sorted_list"] = sortedData;
        if(Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].name;
          let instData = Object.assign({}, data.stacks[name].services.project1);
          instData.instance_id = name;
          instData.description = data.sorted_list[0].description;
          instData.nickname = data.sorted_list[0].nickname;
          setSelectedProject(instData);
          setEditPopup(true);
        }

        client.get('/sdk/project/status/all')
          .then(res => {
            let projectStatus = res.data.data;
            if (projectStatus && 'stacks' in projectStatus) {
              let projectStatusList = projectStatus.stacks;
              Object.keys(projectStatusList).map((projName) => {
                if (projName in data.stacks) {
                  data.stacks[projName].status = projectStatusList[projName];
                }
                return projName;
              });
              setProjectList(data);
            } else {
              setProjectList(data);
            }
            setLoading(false);
            setRefreshLoading(false);
          })
          .catch(err => {
            console.log(err);
            setProjectList(data);
            setLoading(false);
            setRefreshLoading(false);
          });
      setLoading(false);
      setRefreshLoading(false);
    }) .catch(error => {
      console.log(error);
      setProjectList([]);
      setLoading(false);
      setRefreshLoading(false);
    });
  },[]);

  // Volume list //
  const getVolume = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client, {
      retries: 15,
      retryDelay: (retryCount, error) => {
        if (retryCount < 15 && retryCount > 5) {
        } else if (retryCount === 15) {
          swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
        }
        return 3000;
      },
      retryCondition: (error) => {
        return true;
      },
    });
    client.get('/sdk/volume/all')
      .then(res => {
        let data = res.data.data;
        let Data = [];
        {
          Object.keys(data.stacks).map((index) => {
            let volumeData = data.stacks[index].services;
            {
              Object.keys(volumeData).map((index) => {
                let NewVolumeData = volumeData[index];
                Data.push(NewVolumeData);
              })
            }
          })
        }
        setVolumeList(Data)
      }).catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getVolume()
  }, [getVolume])

  const refreshProject = (event) => {
    setRefreshLoading(true);
    document.getElementById('btnRefreshProject').textContent = 'Refreshing...';
    getProjectData();
  };

  useEffect(() => {
    getProjectData();
  }, [getProjectData])

  function getEnvironmentDisplayName(environment) {
    let env = '-';
    const envs = Constants.ENV_LIST;
    envs.map((obj) => {
      if (obj.value === environment) {
        env = obj.name;
      }
      return obj;
    });
    return env;
  }

  const getClockStatus = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    client.get('/system/clock/status/all')
    .then(res => {
      let data=res.data.data.timezone;
      let splitTimeZone = data.split(' (');
      setCurTimeZone(splitTimeZone[0])
      setStandardTZ(splitTimeZone[1])
      let splitZone = splitTimeZone[1].split(',');
      setStandardTZ(splitZone[0])
    }).catch(error => {
      console.error('clock data');
    });
  },[]);

  useEffect(()=>{
    getClockStatus()
  },[])

  const getDateFormate = (Date,CurTimezone) => {     
    try {
      const zonedTargetDate = convertToTimeZone(Date, { timeZone: CurTimezone });
      return zonedTargetDate;
    } catch (err) {
      console.error(err);
      // return new Date(Date);
    }
  };

  return (
    <ContentDiv>
      { (projectList === undefined) &&
        <div className="loading-msg">
          <label>Loading...</label>
          <CircularProgress />
        </div>
      }
      {projectList && !loading &&
        <ResponsiveWrapper style={{ 'padding': '20px' }}>
          <SectionHeader>
            <SectionLabel>Project</SectionLabel>
          </SectionHeader>
          <Row>
            <ActionButtonContainer>
              { projectList.stacks && Object.keys(projectList.stacks).length > 0 &&
                <Button primary id="btnEditProject" name="btnEditProject" onClick={() => editProject()}>Edit</Button>
              }
              {projectList && Object.keys(projectList.stacks).length > 0 &&
                <Button primary id="btnRefreshProject" name="btnRefreshProject" disabled={refreshLoading} onClick={() => refreshProject()}>Refresh</Button>
              }
              <Button primary id="btnAddProject" name="btnAddProject" onClick={() => addNewProject()}>Add</Button>
              <Popup modal closeOnDocumentClick={false} open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                <ProjectPopup isEditPopup={isEditPopup} volumeList={volumeList} closePopup={closePopup} selected_project={isEditPopup ? selectedProject : undefined} projectList={projectList} project={selectedProject} />
              </Popup>
            </ActionButtonContainer>
          </Row>
          {projectList.stacks && Object.keys(projectList.stacks).length > 0 &&
            <TableLayout>
              <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th style={{'width': '50px'}}></Th>
                  <Th style={{'text-align': 'left'}}>Nickname <br /> (Instance ID)</Th>
                  <Th>Status</Th>
                  <Th>Next Restart</Th>
                  <Th>Created</Th>
                  <Th>Environment</Th>
                  <Th>Description</Th>
                  <Th>Access</Th>
                  <Th>Host<br/>(Docker Network)</Th>
                  <Th>Dashboard</Th>
                  <Th>Enabled</Th>
                </tr>

                {projectList && Object.keys(projectList.sorted_list).map((stackName) => {
                  let instName = projectList.sorted_list[stackName].name;
                  let stackData = projectList.sorted_list[stackName].services.project1;
                  let nickname = projectList.stacks[instName].nickname ? projectList.stacks[instName].nickname : '';
                  let environment = stackData.environment;
                  let formattedDate = projectList.stacks[instName].created_time === undefined ? '-' : projectList.stacks[instName].created_time.split(' ');
                  var newDate = new Date(formattedDate * 1000);
                  let calculatedCreatedDate = getDateFormate(newDate, CurTimezone);
                  let periodicRestart = projectList.sorted_list[stackName].services.project1.periodic_restart;
                  let nextRestart = projectList.stacks[instName]?.status?.services?.project1?.next_restart_time;

                  var restartDate;
                  var restartTime;
                  
                  if (typeof nextRestart !== 'undefined') {
                      let convertedRestartDate = new Date(nextRestart * 1000);
                      let formattedRestartDate = getDateFormate(convertedRestartDate, CurTimezone);
                      restartDate = format(new Date(formattedRestartDate), 'yyyy/MM/dd');
                      restartTime = format(new Date(formattedRestartDate), 'HH:mm:ss');
                  }
                
                  if (calculatedCreatedDate === undefined) {
                    return;
                  };
                  let date = format(new Date(calculatedCreatedDate), 'yyyy/MM/dd');
                  let time = format(new Date(calculatedCreatedDate), 'HH:mm:ss');

                  const getPeriodicTime = () => {
                    if (restartDate || restartTime) {
                      return <> <div> {restartDate} <br /> {restartTime} {standaredTZ} </div> </>
                    } else{
                      return '-'
                    }
                  }

                  let networkAccess = Constants.PROJECT_NETWORK_ACCESS[stackData.network_access];
                  let projStatus = 'recovering';
                  if ('status' in projectList.stacks[instName]) {
                    projStatus = projectList.stacks[instName].status.running_status.status;
                  }
                  let enabled = projectList.stacks[instName].enabled;
                  let description = projectList.stacks[instName].description
                  var port = stackData.external_port
                  let host;
                  let dockerName;
                  let env = getEnvironmentDisplayName(environment)
                  let envSplit = env.split('(');
                  if (stackData.network_access === 'deny_all') {
                    host = '127.0.0.1';
                    dockerName = instName + '_default'
                  } else if (stackData.network_access === 'allow_all') {
                    host = deviceID;
                    dockerName = 'host';
                  } else if (stackData.network_access === 'allow_inside_device') {
                    host = instName;
                    dockerName = 'sdk_bridge_network';
                  }
                  let path = '';
                  if (window.location.pathname.includes('/proxy')) {
                    path = window.location.protocol + '//' + window.location.host + '/proxy/' + basename + '/websocket-' + port + '/lab/workspaces/auto-' + instName;
                  } else {
                    path = window.location.protocol + '//' + window.location.host + '/websocket-' + port + '/lab/workspaces/auto-' + instName;
                  }
                    return (
                        <tr>
                            <Td><input type="radio" id="select_radio" style={{ 'cursor': 'pointer' }} defaultChecked={stackName === '0'} name="select_radio" value={stackData.project_name} onClick={() => radioButtonOnChange(stackData.project_name)}></input></Td>
                            <Td>
                              <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                                  <div style={projNameStyle} title={nickname}>{nickname}</div>
                                  <div style={projNameStyle} title={instName}>({instName})</div>
                              </div>
                            </Td>
                            <Td style={{ 'text-align': 'center', 'white-space': 'nowrap' }}>
                              {projStatus === '-' ?
                                '-' :
                                <label className={projStatus + '-sdk'}>
                                  {getDisplayLabel(Constants.STATUS_TYPES, projStatus)}
                                </label>} <br /> 
                            </Td>
                            <Td>
                              {
                                projStatus === 'disabled' ? <label style={{ 'color': 'grey' }}> <b>Disabled</b> </label>
                                  : projStatus === 'recovering' ||  projStatus === 'failed' ?  '-' :
                                  <>
                                    {periodicRestart === true ? 
                                      <div> {getPeriodicTime()} </div>
                                      :
                                      <><label style={{ 'color': 'grey' }}> <b>None</b> </label></>
                                    }
                                  </>
                              }
                            </Td>
                            <Td>{date}<br />{time} {standaredTZ}</Td>
                            <Td>{envSplit[0]}<br/> {'(' + envSplit[1]}</Td>
                            <Td title={description} style={descriptionStyle}>{description === '' ? '-' : description}</Td>
                            <Td>{networkAccess}</Td>
                            <Td>
                              <ContainerHost>
                                <InnerHostContainer title={host}><div style={hostStyle} id={instName + 'host'}> {host} </div><CopyButtonHost primar onClick={() => clickToCopy(instName + 'host')} className={'small-button'} style={{ 'marginLeft': 'auto' }}>Copy</CopyButtonHost></InnerHostContainer>
                              </ContainerHost>
                              <NetworkContainer title={dockerName}>(<div style={hostStyle}>{dockerName}</div>)</NetworkContainer>
                            </Td>
                            <Td>
                            {enabled
                              ? <><LinkIcon /><Link1 href={path} target='_blank'>Open Dashboard</Link1></>
                              : '-'}
                            </Td>
                            <Td><SelectionControl type="switch" labelBefore="true" id={stackData.project_name} name={stackData.project_name} value={projectList.stacks[instName].enabled} checked={projectList.stacks[instName].enabled} onChange={enableToggle} style={{ 'display': 'block', 'paddingTop': '10px' }} /></Td>
                        </tr>
                    );
                })}
              </table>
            </TableLayout>}
          
          {projectList && Object.keys(projectList.stacks).length === 0 &&
            <CenterDiv>
              <Label>No instance found.</Label>
            </CenterDiv>} 
        </ResponsiveWrapper>}
    </ContentDiv>
  );
};

function getDefaultProjectData() {
  return Constants.POPUP_DEFAULT_STATUS;
}

const ProjectPopup = (props) => {
  const { isEditPopup, closePopup, selected_project, projectList, volumeList } = props;
  const [projectSettings, setProjectSettings] = useState(selected_project === undefined ? getDefaultProjectData() : selected_project);
  const [index, setIndex] = useState(0);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedVolumeRow, setSelectedVolumeRow] = useState();
  const [refreshPopUpTable, setRefreshPopUpTable] = useState(false);
  const [volumeIdList, setVolumeIdList] = useState();
  const [rows, setRows] = useState([]);
  const [invalidPaths, setInvalidPaths] = useState([]);
  
  useEffect(() => {
    let pData = {...projectSettings}
    if (pData.env_vars) {
      let envData = pData.env_vars;
      let sortedData = [];
      Object.keys(envData).map((idx) => {
        let data = {};
        Object.keys(envData[idx]).map((i) => {
          data.key = i;
          data.value = envData[idx][i];
        })
        sortedData.push(data);
        return idx;
      });
      pData.env_vars = sortedData;
    }
    if(pData.project_name === ''){
      if(projectList.sorted_list.length !== undefined ){
        pData.project_name = 'project'+(projectList.sorted_list.length+1)
      } else {
        pData.project_name = 'project1';
      }
    }
    setProjectSettings(pData);
    setIndex(Object.keys(pData.env_vars).length);
  },[]);

  useEffect(() => {
    let pData = { ...projectSettings }
    if (pData.volumes) {
      let volumData = pData.volumes;
      const volumeValue = [...rows];
      Object.keys(volumData).map((idx) => {
        Object.keys(volumData[idx]).map((i) => {
          const data = {
            id: new Date(),
            selectedVolume: i,
            mountPathValue:volumData[idx][i][0],
            permission:volumData[idx][i][1]
          }
         
          volumeValue.push(data);
        })
        return idx;
      });
      setRows(volumeValue);
    }
  }, []);

  const handleOnChange = (event) => {
    if(event.target.name === 'project_name') {
      if (event.target.value.charAt(0) === '_') {
        return event.target.value.replace(/[^\w]/g, '');
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
    } else if(event.target.name === 'nickname') {
      if (event.target.value.charAt(0) === ' ') {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
    } else if(event.target.name === 'days'){
      event.target.value = event.target.value.replace(/[^\w]/g, '').replace(/[^\d.-]/g, '')
    }
    setProjectSettings({ ...projectSettings, [event.target.name]: event.target.value });
  };

  const handleRadioChange = (event) => {
    event.persist();
    let data = { ...projectSettings };
    data[event.target.name] = event.target.value;
    setProjectSettings(data);
  };

  function validateLinuxPath(path) {
    // Linux path validation regex pattern
    const pattern = /^\/(?:[^/]+\/)*[^/]+$/;
    return pattern.test(path);
  }

  const saveProject = (event) => {
    let jsonData = Object.assign({}, projectSettings);
    var projectName = jsonData.project_name;
    let env_vars = [];
    let volumes = [];
    const invalidPaths = [];

    Object.keys(rows).map((index)=>{
      let newValues = rows[index].selectedVolume;
      volumes.push(newValues);
    },[])

    let checkValidVolumePath = rows.map((row)=>{
      return row.mountPathValue;
    })

    let duplicateValues = checkValidVolumePath.filter((value, index, array) => array.indexOf(value) !== index);
    let checkValumeSelect = Object.keys(rows).some((idx) => { return (rows[idx].selectedVolume === '' || rows[idx].selectedVolume === [] || rows === [''] || rows === []) === true })
    if (checkValumeSelect) {
      swal('Please select volume', { icon: 'error' });
      return true;
    }

    let checkValumePath = Object.keys(rows).some((idx) => { return (rows[idx].mountPathValue === '' ) })
    if(checkValumePath){
      swal('Please enter mount path.', { icon: 'error' });
      return;
    }

    for (let i = 0; i < checkValidVolumePath.length; i++) {
      if (!validateLinuxPath(checkValidVolumePath[i])) {
        invalidPaths.push(checkValidVolumePath[i]);
      }
    }

    if(jsonData.days === '' ){
      swal(`Please enter days`, { icon: 'error' });
      return;
    }

    if((jsonData.periodic_restart === true) && ((jsonData.days === '0' || jsonData.days === '00') && (jsonData.hours === '0' || jsonData.hours === '00'))){
      swal(`Periodic restart time must be >= 1 hour.`, { icon: 'error' });
      return;
    }
    
    if (invalidPaths.length > 0) {
      setInvalidPaths(invalidPaths);
      swal(`Invalid mount path '${invalidPaths.join(', ')}'.`, { icon: 'error' });
      return;
    }

    if (duplicateValues.length > 0) {
      swal(`Duplicate mount path: '${duplicateValues.join(', ')}'`,{icon:'error'});
      return;
    }

    let action = isEditPopup ? 'updated' : 'added';
    if (event) { event.preventDefault() }
    if (jsonData.project_name === undefined || jsonData.project_name.trim() === ''){
        swal('Please enter instance id.', { icon: 'error' })
        return;
    }
    if(jsonData.environment === 'select') {
      swal('Please select environment.', { icon: 'error' })
      return;
    }

    var projectNameRegex = /^[a-zA-Z0-9][a-zA-Z0-9_]*$/
    if(!projectNameRegex.test(jsonData.project_name)) {
        swal("Invalid project name. Allowed character are [a-zA-Z0-9_] and should start with alpha-numeric")
        return
    }

    if( jsonData.description == undefined || jsonData.description.trim() == "") {
        jsonData.description = ""
    }
    if( jsonData.nickname == undefined || jsonData.nickname.trim() == "") {
      jsonData.nickname = ""
    }

    for(var i=0; i < projectSettings.env_vars.length; i++){
      for(var j=i+1; j < projectSettings.env_vars.length; j++){
        if(projectSettings.env_vars[i].key === projectSettings.env_vars[j].key){
          swal('Duplicate key.', {icon: 'error'});
          document.getElementById('key-' + j).style.borderColor = 'red';
          // document.getElementById('value-' + j).style.borderColor = 'red';
          return;
        }
      }
    }

    for(var k=0; k < projectSettings.env_vars.length; k++){
      if(document.getElementById('env_vars_row-' + k)){
        let envData = Object.assign({}, projectSettings.env_vars[k]);
        let key = envData.key;
        let value = envData.value;
        if(key === ''){
          swal('Please enter key', {icon: 'error'});
          document.getElementById('key-' + k).style.borderColor = 'red';
          return;
        }
        if(value === ''){
          swal('Please enter value', {icon: 'error'});
          document.getElementById('value-' + k).style.borderColor = 'red';
          return;
        }
        let env = {}
        env[key]= value;
        env_vars[k] = env;
      }
    }
    
    let finalJson ={}
    finalJson.description = jsonData.description;
    finalJson.nickname = jsonData.nickname;
    finalJson.enabled = projectList.stacks[projectName] === undefined ? true : projectList.stacks[projectName].enabled;
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = 'project';
    finalJson.services = {};
    delete jsonData.description;
    delete jsonData.enabled;
    delete jsonData.nickname;
    finalJson.services.project1 = Object.assign({}, jsonData);
    finalJson.services.project1.type = 'jupyter_lab';
    finalJson.services.project1.enabled = true;
    finalJson.services.project1.env_vars = env_vars;
    finalJson.services.project1.volumes = rows.map((row) => ({
      [row.selectedVolume]: [row.mountPathValue, row.permission || "ro"]
    }));
    if(action === 'updated') {
      axios.patch(Constants.EDGE_API_ENDPOINT + '/sdk/project/' + projectName + '/all', finalJson)
        .then(res => {
            swal('Instance updated successfully.', { icon: 'success' })
            setTimeout(function () { window.location.reload() }, 2000)
      }).catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
    } else {
      axios.put(Constants.EDGE_API_ENDPOINT + '/sdk/project/' + projectName + '/all', finalJson)
        .then(res => {
            swal('Instance added successfully.', { icon: 'success' })
            setTimeout(function () { window.location.reload() }, 2000)
        }).catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    }
  };

  const deleteProject = (event) => {
    let projectName = projectSettings.project_name;
    swal({
      text: 'Enter instance id to delete',
      content: 'input',
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
    .then((name) => {
      // user has cancle the delete operation by clicking No
      if (name === null) {
        return;
      }
      if (name === projectName) {
        axios.delete(Constants.EDGE_API_ENDPOINT + '/sdk/project/' + projectName + '/all')
          .then(res => {
            swal('Instance delete request submitted successfully.', { icon: 'success' });
            setTimeout(function () { window.location.reload(); }, 2000);
          })
          .catch(error => {
            if (error.response) {
              var errorObj = error.response.data;
              swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
            } else {
              swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
            }
          });
      } else {
        return swal({ text: 'Instance id not match.', icon: 'error' });
      }
    });
  };

  const handleAddRow = () => {
    if(Object.keys(projectSettings.env_vars).length >= 20){
      swal('Maximum 20 variables can be configured.', {icon: 'error'});
      return;
    }
    setRefreshPopUpTable(false);
    let newIndex = index + 1;
    setIndex(newIndex);
    projectSettings.env_vars.push(getEnvironmentVariable());
    setProjectSettings(projectSettings);
  };

  const handleAddVolume = () => {
    setRefreshPopUpTable(false);
    let checkValumeSelect = Object.keys(rows).some((idx) => { return (rows[idx].selectedVolume === '' || rows[idx].selectedVolume === [] || rows === [''] || rows === []) === true })
    if (checkValumeSelect) {
      swal('Please select volume', { icon: 'error' });
      return true;
    }
    if (rows.length > volumeIdList.length - 1) {
      swal('No more volumes available.', { icon: 'error' });
      return;
    } else {
      const newRow = {
        id: Date.now(),
        selectedVolume: '',
        mountPathValue: '',
        permission:''
      };
      setRows([...rows, newRow]);
      const updatedRows = [...rows, newRow];
      setRows(updatedRows);
    }
  }

  const handleVolumeOnChange = (id, selectedVolume) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        const mountPathValue = selectedVolume === '' ? '' : `/volume/${selectedVolume}`;
        const permission = selectedVolume === '' ? 'ro' : 'ro';
        return { ...row, selectedVolume, mountPathValue, permission };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handlePathOnChange = (e, rowId) => {
    if(e.target.name === 'value'){
      e.target.value = e.target.value.replace(/[$:]/g,'')
    }
    const updatePath = rows.map((row) => {
      if (row.id === rowId) {
        return { ...row,mountPathValue: e.target.value };
      }
      return row;
    });
    setRows(updatePath);
  };

  const handlePermissionChange = (e, rowId) => {
    const updatePermission = rows.map((row) => {
      if (row.id === rowId) {
        return { ...row, permission: e.target.value || "ro" };
      }
      return row;
    });
    setRows(updatePermission);
  };


  const radioOnChangeInPopup = (idx) => {
    setSelectedRow(idx);
    setRefreshPopUpTable(false);
  };

  const radioOnChangeInVolumePopup = (idx) => {
    setSelectedVolumeRow(idx);
    setRefreshPopUpTable(false);
  };

  const handleDeleteEnv = () => {
    projectSettings.env_vars.splice(selectedRow, 1);
    setProjectSettings(projectSettings);
    setRefreshPopUpTable(true);
    setSelectedRow('');
  };

  const getAvailableVolumes = (currentRow) => {
    const selectedVolumes = rows.filter((row) => row.id !== currentRow.id).map((row) => row.selectedVolume);
    return volumeIdList.filter((volume) => !selectedVolumes.includes(volume));
  };

  const handleDeleteVolume = (id) => {
    if (selectedVolumeRow) {
      const updatedRows = rows.filter((row) => row.id !== selectedVolumeRow);
      setRows(updatedRows);
      setSelectedVolumeRow(null);
      setRefreshPopUpTable(true);
      const radioIndex = rows.findIndex((row) => row.id === selectedVolumeRow);
      if (radioIndex !== -1) {
        const radioRow = rows[radioIndex + 1];
        setSelectedVolumeRow(radioRow ? radioRow.id : null);
      }
    }
  };

  useEffect(() => {
    let arr = [];
    Object.keys(volumeList).map((index) => {
      let newList = volumeList[index].instance_id;
      arr.push(newList);
    })
    setVolumeIdList(arr);
  }, [volumeList])

  const updateEnvironmentSetting = (id, name, value) => {
    let idx = id.substring(id.indexOf('-') + 1);
      let data = { ...projectSettings };
      if(data.env_vars[idx]){
        data.env_vars[idx][name] = value;
        setProjectSettings(data);
      }
  };

  const handleToggleChange = (value, event) => {
    const name = event.target.name;
    setProjectSettings({ ...projectSettings, [name]: value });
  };

  const handleOnChangeInnerInput = (event, idx) => {
    let pElement = document.getElementById(event.target.id);
    if(pElement){
      pElement.style.borderColor = 'lightgray';
    }
    if((event.target.id === 'key-'+ idx || event.target.id === 'value-'+ idx) && event.target.value.includes(' ')){
      event.target.value = event.target.value.replace(/\s/g, '');
    } else {
      if(event.target.id === 'key-'+ idx ) {
        if (event.target.value.charAt(0) === '_' || (/^[0-9]/.test(event.target.value.charAt(0)))) {
          return event.target.value.replace(/[^\w]/g, '');
        } else {
          event.target.value = event.target.value.replace(/__/g, '_');
          event.target.value = event.target.value.replace(/[^a-zA-Z_]/g, '');
        }
      } else if(event.target.id === 'value-'+ idx) {
        event.target.value = event.target.value.replace(/[^\w/:,]/g, '');
      }
    }
    if(event.target.id === 'key-'+ idx) {
      updateEnvironmentSetting(event.target.id, event.target.name, event.target.value.toUpperCase());
    } else {
      updateEnvironmentSetting(event.target.id, event.target.name, event.target.value);
    }
  };

  return (
    <div>
      {projectSettings !== undefined &&
        <PopupContainer>
          <SectionHeader1 style={{ 'display': 'flex' }}>
            <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Project</SectionLabel>
            {isEditPopup &&
              <div style={{ 'marginLeft': 'auto' }}>
                <Button danger id="btnDeleteProject" name="btnDeleteProject" style={{ 'margin': '0px' }} onClick={deleteProject}>Delete</Button>
              </div>
            }
          </SectionHeader1>
          <Tabs defaultTab="general" style={{ 'padding': '6px' }}>
            <TabList>
              <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
              <Tab tabFor="environment_variable" className={'rwt__tab'}>Environment Variable</Tab>
              <Tab tabFor="volumes" className={'rwt__tab'}>Volume</Tab>
            </TabList>
            <TabPanel tabId="general">
            <InputContainer>
            <InputFieldContainer>
              <MandatoryLabel>Instance ID</MandatoryLabel>
              <TextFieldSmall id="project_name" autoComplete="off" name="project_name" maxLength="63" disabled={isEditPopup} value={projectSettings.project_name} onChange={handleOnChange} style={{'flex-grow':'1'}} />
            </InputFieldContainer>
            <InputFieldContainer>
              <TextFieldLabel>Nickname</TextFieldLabel>
              <TextFieldSmall id='nickname' autoComplete='off' name='nickname'  value={projectSettings.nickname} onChange={handleOnChange} maxLength='100' style={{'flex-grow':'1'}}/>
            </InputFieldContainer>
            <InputFieldContainer>
              <MandatoryLabel>Environment</MandatoryLabel>
              <SelectField id="environment" name="environment" style={{ 'margin-top': '10px', 'flex-grow':'1' }} value={projectSettings.environment} onChange={handleOnChange}>
                <SelectOptions items={Constants.ENV_LIST}/>
              </SelectField>
            </InputFieldContainer>
            <InputFieldContainer style={{alignItems: 'center'}}>
              <TextFieldLabel>Description</TextFieldLabel>
              <div style={{'marginTop':'20px', flexGrow: '1', 'height':'auto', overflow:'auto'}}>
                <TextArea id="description" autoComplete="off" name="description" maxLength="255" value={projectSettings.description}  onChange={handleOnChange} />    
              </div>
            </InputFieldContainer>
            <Row>
                <fieldset style={{ 'margin-top': '20px', 'margin-left': '-1px', 'width': '100%'}}>
                  <legend>Network Access</legend>
                  <Row style={{ 'marginTop': '15px' }}>
                    <div style={{ 'display': 'flex', 'paddingBottom': '15px', justifyContent: 'space-around', flexWrap: 'wrap', gap: '0 5px' }}>
                      <div>
                        <input type="radio" id="network_access" name="network_access" value="deny_all" checked={projectSettings.network_access === 'deny_all' || false} onChange={handleRadioChange} /> {Constants.PROJECT_NETWORK_ACCESS.deny_all}
                      </div>
                      <div>
                        <input type="radio" id="network_access" name="network_access" value="allow_inside_device" checked={projectSettings.network_access === 'allow_inside_device' || false} onChange={handleRadioChange} /> {Constants.PROJECT_NETWORK_ACCESS.allow_inside_device}
                      </div>
                      <div>
                        <input type="radio" id="network_access" name="network_access" value="allow_all" checked={projectSettings.network_access === 'allow_all' || false} onChange={handleRadioChange} /> {Constants.PROJECT_NETWORK_ACCESS.allow_all}
                      </div>
                    </div>
                    </Row>
                  </fieldset>
                </Row>
            <Row>
                <fieldset style={{ 'margin-top': '20px', 'margin-left': '-1px', 'width': '100%'}}>
                  <legend>Periodic Restart</legend>
                  <Row style={{ 'marginTop': '10px','overflow':'auto' }}>
                    <div style={{ 'display': 'flex', 'paddingBottom': '15px', flexWrap: 'wrap', gap: '0 5px', 'marginLeft':'14px' }}>
                      <div style={{'width':'120px'}}>
                      <SelectionControl id="periodic_restart" type="switch" label={projectSettings.periodic_restart ? "Enabled" : "Disabled"} name="periodic_restart" labelBefore={true} value={projectSettings.periodic_restart} checked={projectSettings.periodic_restart || false} onChange={handleToggleChange} />
                      </div>
                      <PeriodicRestartInput>
                      <Label style={{ 'margin': '-1px 10px 0 0px', 'flex':'none', 'color':`${projectSettings.periodic_restart ? 'black' :  'rgb(135, 149, 139)' }` }}>Interval</Label>
                          <SmallTextField id='days' name='days' style={{ 'marginLeft': '5px', 'marginRight': '10px', 'textAlign': 'center', 'color':`${projectSettings.periodic_restart ? 'black' :  'rgb(103 109 105)' }` }} value={projectSettings.days} disabled={!projectSettings.periodic_restart} onChange={handleOnChange} maxLength='2' />
                          <Label style={{'color':`${projectSettings.periodic_restart ? 'black' :  'rgb(135, 149, 139)' }`}}>day(s)</Label>
                          <SelectField className='schedule-hours' id='hours' name='hours' value={projectSettings.hours} disabled={!projectSettings.periodic_restart} onChange={handleOnChange}>
                            <SelectOptions items={Constants.HOURS} />
                          </SelectField>
                          <Label style={{ 'marginLeft': '-20px', 'color':`${projectSettings.periodic_restart ? 'black' :  'rgb(135, 149, 139)' }` }}>hour(s)</Label>
                      </PeriodicRestartInput>
                    </div>
                    <MessageContaier>
                          <MessageLabel>
                              Enabling periodic restart helps recovery from memory leaks.
                          </MessageLabel>
                        </MessageContaier>
                    </Row>
                  </fieldset>
                </Row>
          </InputContainer>
            </TabPanel>
            <TabPanel tabId="environment_variable">
              <InputContainer style={{ padding: '0' }}>
                <div style={{ 'padding': '10px 2%' }}>
                  <Row>
                    <RightDiv style={{ 'display': 'inline-block', 'marginRight': '2px' }}>
                      {selectedRow && <Button id="btnDeleteEnvironment" name="btnDeleteEnvironment" onClick={handleDeleteEnv}>Delete</Button>}
                      <Button primary id="btnEnv" name="btnEnv" style={{ 'marginRight': '0' }} onClick={handleAddRow}>Add</Button>
                    </RightDiv>
                  </Row>
                  <Row >
                    <TableLayout style={{ 'overflow': 'auto', 'height': '250px' }}>
                      {(refreshPopUpTable || projectSettings) &&
                        <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                          <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                            <Th></Th>
                            <Th style={{ 'textAlign': 'left' }}>Key</Th>
                            <Th>Value</Th>
                          </tr>
                          {Object.keys(projectSettings.env_vars).map((idx) => (
                            <tr id={'env_vars_row-' + idx}>
                              <Td>
                                <input type="radio" id={'environment_row_radio-'} value={idx} name={'environment_row_radio'} style={{ 'cursor': 'pointer' }} onClick={() => radioOnChangeInPopup(idx)}></input>
                              </Td>
                              <Td style={{ 'padding': '5px' }}><TextField autoComplete="off" maxLength="32" id={'key-' + idx} name={'key'} value={projectSettings.env_vars[idx].key} disabled={projectSettings.env_vars[idx].disabled} onChange={(e) => handleOnChangeInnerInput(e, idx)} /></Td>
                              <Td style={{ 'padding': '5px' }}><TextField autoComplete="off" maxLength="32" id={'value-' + idx} name={'value'} value={projectSettings.env_vars[idx].value} onChange={(e) => handleOnChangeInnerInput(e, idx)} /></Td>
                            </tr>))}
                        </table>}
                    </TableLayout>
                  </Row>
                </div>
              </InputContainer>
            </TabPanel>
            <TabPanel tabId="volumes">
              <InputContainer style={{ padding: '0' }}>
                <div style={{ 'padding': '10px 2%' }}>
                  <Row>
                    <RightDiv style={{ 'display': 'inline-block', 'marginRight': '2px' }}>
                      {selectedVolumeRow && <Button id="btnDeleteVolume" name="btnDeleteVolume" onClick={() => handleDeleteVolume(selectedVolumeRow)}>Detach </Button>}
                      <Button primary id="btnVolume" name="btnVolume" style={{ 'marginRight': '0' }} onClick={handleAddVolume}>Attach</Button>
                    </RightDiv>
                  </Row>
                  <Row>
                    <TableLayout style={{ 'overflow': 'auto', 'height': '250px' }}>
                      {(refreshPopUpTable || rows) &&
                        <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                          <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                            <Th></Th>
                            <Th style={{ 'textAlign': 'left' }}>Name</Th>
                            <Th style={{ 'textAlign': 'left' }}>permission</Th>
                            <Th style={{ 'textAlign': 'left' }}>Mount Path</Th>
                          </tr>
                          <tr>
                            <Td style={{ width: '35px' }}>
                            <input type="radio" disabled={true} />
                            </Td>
                            <VolumeTd style={{ 'marginBottom': '10px' }}>
                              <SelectField style={{ 'margin-top': '10px', marginBottom: '10px', 'flex-grow': '1' }} disabled={true}>
                                <option value="">SDK</option>
                              </SelectField>
                            </VolumeTd>
                            <PermissionTd>
                              <SelectField style={{ 'margin-top': '10px', marginBottom: '10px', 'flex-grow': '1' }}  disabled={true} >
                                <option value="rw">read-write</option>
                              </SelectField>
                            </PermissionTd>
                            <Td>
                              <TextField autoComplete="off" value={"/sdk"} disabled={true} />
                            </Td>
                          </tr>
                          <tr>
                            <Td style={{ width: '35px' }}>
                            <input type="radio" disabled={true} />
                            </Td>
                            <VolumeTd style={{ 'marginBottom': '10px' }}>
                              <SelectField style={{ 'margin-top': '10px', marginBottom: '10px', 'flex-grow': '1' }} disabled={true}>
                                <option value="">Data</option>
                              </SelectField>
                            </VolumeTd>
                            <PermissionTd>
                              <SelectField style={{ 'margin-top': '10px', marginBottom: '10px', 'flex-grow': '1' }}  disabled={true} >
                                <option value="rw">read-write</option>
                              </SelectField>
                            </PermissionTd>
                            <Td>
                              <TextField autoComplete="off" value={"/data"} disabled={true} />
                            </Td>
                          </tr>
                          {rows.map((row) => (
                            <tr id={'volumes_vars_row-' + row.id}>
                              <Td>
                                <input type="radio" id={'volume_row_radio-'} value={row.id} name={'volume_row_radio'} style={{ 'cursor': 'pointer' }} onClick={() => radioOnChangeInVolumePopup(row.id)}></input>
                              </Td>
                              <VolumeTd style={{'marginBottom': '10px' }}>
                                <SelectField name="volumeName" id={'volume-' + row.id} style={{ 'margin-top': '10px', marginBottom: '10px', 'flex-grow': '1' }} value={row.selectedVolume} onChange={(e) => handleVolumeOnChange(row.id, e.target.value)}>
                                  <option value="">Select Volume</option>
                                  {
                                    getAvailableVolumes(row).map((volume)=>{
                                      let myArr = row[volume];
                                      return (
                                        <>
                                          <option key={volume} value={volume}>
                                            {volume}
                                          </option>
                                        </>
                                      )
                                    })
                                  }
                                </SelectField>
                              </VolumeTd>
                              <PermissionTd>
                                {row.selectedVolume === '' ? (
                                  <div>
                                    <SelectField
                                      id={'permission-' + row.id}
                                      name={'permission'}
                                      value={row.permission}
                                      onChange={(e) => handlePermissionChange(e, row.id)}
                                    >
                                      <option value='ro'>read-only</option>
                                      <option value='rw'>read-write</option>
                                    </SelectField>
                                  </div>
                                ) : (
                                  Object.keys(volumeList).map((ind) => {
                                    let permission = volumeList[ind].permission;
                                    let volumeType = volumeList[ind].volume_type;
                                      if (volumeList[ind].instance_id === row.selectedVolume) {                                      
                                      if (volumeType === 'nfs' && permission === 'rw') {
                                        return (
                                          <div>
                                            <SelectField sty id={'permission-' + row.id} name={'permission'} value={row.permission} onChange={(e) =>handlePermissionChange(e, row.id)}>
                                              <option value='ro'>read-only</option>
                                              <option value='rw'>read-write</option>
                                            </SelectField>
                                          </div>
                                        );
                                      }
                                      else if (volumeType === 'nfs' && permission === 'ro') {
                                        return (
                                          <div>
                                             <SelectField sty id={'permission-' + row.id} name={'permission'} value={row.permission} onChange={(e) =>handlePermissionChange(e, row.id)}>
                                              <option value='ro'>read-only</option>
                                              <option value='rw' disabled={true}>read-write</option>
                                            </SelectField>
                                          </div>
                                        );
                                      } else if (volumeType === 'named' && permission === 'rw') {
                                        return (
                                          <div>
                                             <SelectField sty id={'permission-' + row.id} name={'permission'} value={row.permission} onChange={(e) =>handlePermissionChange(e, row.id)}>
                                              <option value='ro'>read-only</option>
                                              <option value='rw'>read-write</option>
                                            </SelectField>
                                          </div>
                                        );
                                      } 
                                    }
                                  })
                                )
                              }
                              </PermissionTd>
                              <Td>
                                <TextField autoComplete="off" maxLength="32" id={'value-' + row.id} name={'value'} value={row.mountPathValue || ''} onChange={(e) => handlePathOnChange(e, row.id)} />
                              </Td>
                            </tr>))}
                        </table>}
                    </TableLayout>
                  </Row>
                </div>
              </InputContainer>
            </TabPanel>
            </Tabs>
          <Row>
            <PopupButtons>
              <Button primary onClick={saveProject}>Save</Button>
              <Button id="btnCancelProject" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row>
        </PopupContainer>
      }
    </div>
  );
};

export default Project;
